import React, {useCallback, useEffect, useState} from "react";
import './index.less';
import {Box, Button, Field, Form, Grid, Icon, Input, Menu, Message, Search, Shell, Tab, Tree} from "@alifd/next";
import {PageContainer} from "@/components/lib";
import styled from "@emotion/styled";
import {TreeObject} from "@/types/core/TreeObject";
import * as groupModel from "@/models/admin/group.model";
import {useToggle} from "react-use";
import {defaultFormItemLayout} from "@/contants/Constans";
import TabContentContainer from "@/components/TabContentContainer";
import {openErrorNotification} from "@/utils/notificationUtil";
import AddGroupDialog from "./components/AddGroupDialog";
import {Group} from "@/types/admin/Group";
import {loopForContextMenu, loopMatchNodeByLabel, loopParseTreeNode} from "@/utils/TreeUtil";
import GroupAttributeList from "./components/GroupAttributeList";
import GroupMemberList from "./components/GroupMemberList";
import AssignRoleForGroup from "./components/AssignRoleForGroup";
import PageTitle from "@/components/PageTitle";

const GroupManagement = () => {
    // 查询组树
    const [resourceTreeDataSource, setResourceTreeDataSource] = useState<TreeObject<Group>[]>([]);
    const {data: groupTree, isLoading: isLoadingTree} = groupModel.useFindGroupTree("Master");
    const {mutateAsync: deleteGroupAsync} = groupModel.useDeleteGroup();
    const {mutateAsync: updateGroupAsync} = groupModel.useUpdateGroup();

    const groupInfoField = Field.useField({forceUpdate: false});

    const [groupId, setGroupId] = useState<string>();
    const [addResourceDialogVisible, toggleResourceDialogVisible] = useToggle(false);
    // 树展开状态
    const [treeExpandState, setExpandTreeState] = useState<{ expandedKeys: string[], autoExpandParent: boolean }>({
        expandedKeys: [],
        autoExpandParent: true
    });
    // 搜索匹配KEYS
    const [matchedKeys, setMatchedKeys] = useState<string[]>([]);
    // 选中节点KEYS
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    // 右键选中节点
    const [rightClickSelectedNode, setRightClickSelectedNode] = useState<TreeObject<Group> | undefined>(undefined);

    // 查询选中资源
    const {data: selectedResource} = groupModel.useFindGroupById(groupId);
    // 刷新树
    const refreshGroupTree = groupModel.useRefreshGroupTree();

    useEffect(() => {
        if (selectedResource) {
            groupInfoField.setValues(selectedResource)
        }
    }, [selectedResource]);

    useEffect(() => {
        if (groupTree && groupTree.length) {
            const tree = loopParseTreeNode(groupTree, (x) => {
                const newNode = {
                    key: x.key,
                    label: x.label,
                    order: x.order,
                    children: x.children || [],
                    // type: x.extra?.resourceType || "LEFT_MENU",
                    isLeaf: !x.children?.length,
                    // icon: x.extra?.icon ? <Icon type={x.extra?.icon}/> : undefined
                } as TreeObject<Group>;
                return newNode;
            });
            const newTree = loopForContextMenu(tree, createContextMenu);
            setResourceTreeDataSource(newTree)
        }
    }, [groupTree]);

    // 创建右键菜单
    const createContextMenu = (e: any, tree: TreeObject<Group>[]) => {
        e.preventDefault();
        const target = e.target;
        const {top, left} = target.getBoundingClientRect();
        const label = target.ariaLabel || target.innerHTML;
        const selectNode = loopMatchNodeByLabel(tree, label);
        if (!selectNode) {
            return;
        }
        const key = selectNode.key;
        // 设置当前右键选中节点
        setRightClickSelectedNode(selectNode);

        Menu.create({
            target: e.target,
            offset: [e.clientX - left, e.clientY - top],
            className: "context-menu",
            popupClassName: "context-menu",
            onItemClick: console.log,
            // selectedKeys: selectedKeys,
            selectMode: "single",
            // onSelect: handleSelectContextMenu,
            children: [
                <Menu.Item key="21" onClick={() => handleAddGroup(key)}>新建组</Menu.Item>,
                <Menu.Item key="23" onClick={() => handleRenameGroup(key)}>重命名</Menu.Item>,
                <Menu.Item key="24" onClick={() => handleDeleteGroup(key)}>删除组</Menu.Item>
            ]
        });

    };

    // 搜索树节点
    const handleSearchTreeNode = (value: any) => {
        value = value.trim();
        if (!value) {
            setMatchedKeys([])
            return;
        }

        const matchedKeys: string[] = [];
        const loop = (data: TreeObject<Group>[] | undefined) => {
            if (data && data.length) {
                data.forEach(item => {
                    if (item.label.indexOf(value) > -1) {
                        matchedKeys.push(item.key);
                    }
                    if (item.children && item.children.length) {
                        loop(item.children);
                    }
                });
            }
        }
        loop(resourceTreeDataSource);

        setExpandTreeState({
            expandedKeys: [...matchedKeys],
            autoExpandParent: true
        });
        setMatchedKeys(matchedKeys);
    }

    // 添加组
    const handleAddGroup = (parentId: string | undefined) => {
        if (!parentId) {
            setRightClickSelectedNode(undefined);
        }
        toggleResourceDialogVisible()
    }
    // 重命名组
    const handleRenameGroup = (key: string) => {
    }
    // 删除组
    const handleDeleteGroup = (key: string) => {
        deleteGroupAsync(key).then(() => {
            Message.success("删除成功！")
        }).catch(reason => {
            openErrorNotification("删除失败", reason.message)
        })
    }

    // 过滤树节点
    const filterTreeNode = (node: any): boolean => {
        return (
            matchedKeys && (matchedKeys.indexOf(node.props.eventKey) > -1)
        );
    };

    // 展开树
    const handleExpandTree = (expandedKeys: string[], extra: Object) => {
        setExpandTreeState({
            expandedKeys: expandedKeys,
            autoExpandParent: false
        });
    };

    // 选择树节点
    const handleSelectTreeNode = (selectedKeys: string[], extra: Object) => {
        const selectedKey = selectedKeys[0];
        setGroupId(selectedKey);
        setSelectedKeys(selectedKeys);
    }

    // 更新组信息
    function handleSubmitGroupInfo() {
        groupInfoField.validate((errors, values) => {
            if (errors) {
                return;
            }
            const group = groupInfoField.getValues<Partial<Group>>();
            updateGroupAsync(group).then(() => {
                Message.success("更新成功！");
            }).catch(reason => {
                openErrorNotification("更新失败", reason.message);
            })
        })
    }

    // 添加组成功回调
    const onAddResourceSuccessCallback = useCallback(async (resourceId: string) => {
        await refreshGroupTree();
        const keys = [resourceId];
        setSelectedKeys(keys);
        // 展开
        setExpandTreeState({
            expandedKeys: [...keys],
            autoExpandParent: true
        });
        setGroupId(resourceId);
    }, ["realmId"]);

    return (
        <PageContainer style={{padding: 0}}>
            <Shell
                className={"iframe-hack"}
                device={"desktop"}
                style={{}}
            >

                <Shell.LocalNavigation style={{backgroundColor: '#fff'}}>

                    <Box direction={"column"} style={{padding: '1rem'}}>
                        <PageTitle title="用户组管理" />

                        <Box direction={"row"} justify={"space-between"} align={"baseline"} spacing={4}>
                            <Search
                                shape="simple"
                                size="medium"
                                style={{marginBottom: "10px" }}
                                onChange={handleSearchTreeNode}
                            />
                            <Icon type="add" size={"xs"} onClick={() => {handleAddGroup(undefined)}}/>
                            <Icon type="refresh" size={"xs"} title="刷新" onClick={ async () => {await refreshGroupTree()}}/>
                        </Box>

                        {
                            resourceTreeDataSource.length > 0 ? <Tree
                                selectedKeys={selectedKeys}
                                expandedKeys={treeExpandState.expandedKeys}
                                autoExpandParent={treeExpandState.autoExpandParent}
                                filterTreeNode={filterTreeNode}
                                onExpand={handleExpandTree}
                                onSelect={handleSelectTreeNode}
                                multiple={false}
                                editable={false}
                                dataSource={resourceTreeDataSource}
                            /> : <div>无数据</div>
                        }

                    </Box>

                </Shell.LocalNavigation>

                <Shell.Content style={{backgroundColor: '#fff'}}>
                    <ContentContainer>
                        <Tab>
                            <Tab.Item title="组信息" key="1">
                                <TabContentContainer>
                                    {
                                        !groupId ? <div>请选择用户组</div> : <div style={{minWidth: '60rem'}}>
                                            <Form {...defaultFormItemLayout} colon={true} field={groupInfoField}>
                                                <Form.Item label="ID" required={true} isPreview={true}>
                                                    <Input name={'groupId'}/>
                                                </Form.Item>
                                                {/*<Form.Item label="父级" isPreview={true}>
                                                <Input name="parentId"/>
                                            </Form.Item>*/}
                                                <Form.Item label="组名称" required={true}>
                                                    <Input name="groupName"/>
                                                </Form.Item>
                                                <Form.Item label="显示名称" required={true}>
                                                    <Input name="displayName"/>
                                                </Form.Item>
                                                <Form.Item label="描述" required={true}>
                                                    <Input.TextArea name="description"/>
                                                </Form.Item>
                                                <Form.Item label="组类型">
                                                    <Input name="groupType"/>
                                                </Form.Item>
                                                <Form.Item label="排序号">
                                                    <Input name="sortNo"/>
                                                </Form.Item>
                                                <Form.Item label="图标">
                                                    <Input name="icon"/>
                                                </Form.Item>
                                            </Form>

                                            <Grid.Row>
                                                <Grid.Col span="8"></Grid.Col>
                                                <Grid.Col span="16">
                                                    <Button type={"primary"} onClick={handleSubmitGroupInfo}>提交</Button>
                                                </Grid.Col>
                                            </Grid.Row>
                                        </div>
                                    }
                                </TabContentContainer>
                            </Tab.Item>
                            <Tab.Item title="组属性" key="2">
                                <TabContentContainer>
                                    {
                                        !groupId ? <div>请选择用户组</div> : <GroupAttributeList groupId={groupId} />
                                    }
                                </TabContentContainer>
                            </Tab.Item>
                            <Tab.Item title="组成员" key="3">
                                <TabContentContainer>
                                    {
                                        !groupId ? <div>请选择用户组</div> : <GroupMemberList groupId={groupId} />
                                    }
                                </TabContentContainer>
                            </Tab.Item>
                            <Tab.Item title="分配角色" key="4">
                                <TabContentContainer>
                                    {
                                        !groupId ? <div>请选择用户组</div> : <AssignRoleForGroup groupId={groupId} />
                                    }
                                </TabContentContainer>
                            </Tab.Item>
                        </Tab>
                    </ContentContainer>
                </Shell.Content>
            </Shell>

            <AddGroupDialog visible={addResourceDialogVisible} toggleVisible={toggleResourceDialogVisible}
                               parent={rightClickSelectedNode} onSuccess={onAddResourceSuccessCallback}/>

        </PageContainer>
    )
}

const ContentContainer = styled.div`
    height: calc(100vh - 54px);
    background-color: #fff;
`

export default GroupManagement