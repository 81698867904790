import {useMutation, useQuery, useQueryClient} from "react-query";
import {Resource} from "@/types/admin/Resource";
import {HttpError} from "@/httpclient";
import * as resourceService from "@/services/admin/resource.service";
import {TreeObject} from "@/types/core/TreeObject";
import {useCallback} from "react";
import {AssignedResourcesResponse} from "@/types/admin/AssignedResourcesResponse";

export const RESOURCE_TREE_CACHE_KEY = "ResourceTree";

export function useFindResourceTree(appId: string | undefined) {
    // const [resourceTreeDataSource, setResourceTreeDataSource] = useState<TreeObject[]>();
    return /*const {data, isLoading} =*/ useQuery<TreeObject<Resource>[], HttpError>([RESOURCE_TREE_CACHE_KEY, appId], () => {
        return resourceService.findResourceTree(appId)
    }, {
        enabled: !!appId
    })

    /*useEffect(() => {
        if (data) {
            setResourceTreeDataSource(data)
        }
    }, [data])

    return {
        resourceTreeDataSource: resourceTreeDataSource,
        isLoadingResourceTree: isLoading
    }*/

}

export function useFindResourceById(resourceId: string | undefined) {
    return useQuery<Resource, HttpError>(["FindResourceById", resourceId], () => {
        return resourceService.findResourceById(resourceId)
    }, {
        enabled: !!resourceId
    })
}

export function useSaveResource() {
    const queryClient = useQueryClient();
    return useMutation<Resource, HttpError, Partial<Resource>>(async (resource: Partial<Resource>) => {
        return resourceService.saveResource(resource)
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(RESOURCE_TREE_CACHE_KEY)
        }
    })
}

export function useDeleteResource() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, string>(async (resourceId: string) => {
        return resourceService.deleteResource(resourceId)
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(RESOURCE_TREE_CACHE_KEY)
        }
    })
}

export function useUpdateResource() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, Partial<Resource>>(async (resource: Partial<Resource>) => {
        return resourceService.updateResource(resource)
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(RESOURCE_TREE_CACHE_KEY)
        }
    })
}

export function useRefreshResourceTree(appId?: string) {
    const queryClient = useQueryClient();
    return useCallback(() => {
        return queryClient.invalidateQueries([RESOURCE_TREE_CACHE_KEY])
    }, [queryClient, appId])
}


// ================= permission ==================================

export function useFindAssignedResourcesByRoleWithId(id: string | undefined, roleId: string | undefined) {
    return useQuery<AssignedResourcesResponse, HttpError>([RESOURCE_TREE_CACHE_KEY, id], () => {
        return resourceService.findAssignedResourcesByRoleWithAppId(String(id), String(roleId))
    }, {
        enabled: !!id && !!roleId
    })
}

export function useAssignResourcesToRole(roleId: string) {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, string[]>(async (resourceIds: string[]) => {
        return resourceService.assignResourcesToRole(roleId, resourceIds)
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(RESOURCE_TREE_CACHE_KEY)
        }
    })
}
