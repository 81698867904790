import {Notification} from '@alifd/next';
import {ReactNode} from "react";

export const openSuccessNotification = (title: ReactNode, content: string) => {
    openNotification(title, content, "success")
};
export const openWarningNotification = (title: ReactNode, content: string) => {
    openNotification(title, content, "warning")
};
export const openErrorNotification = (title: ReactNode, errorMessage: string) => {
    openNotification(title, errorMessage, "error")
};
export const openNoticeNotification = (title: ReactNode, errorMessage: string) => {
    openNotification(title, errorMessage, "notice")
};
export const openHelpNotification = (title: ReactNode, errorMessage: string) => {
    openNotification(title, errorMessage, "help")
};

export const openNotification = (title: ReactNode, content: string,
                                 type?: 'success' | 'error' | 'warning' | 'notice' | 'help',
                                 placement?: 'topRight' | 'topLeft' | 'bottomLeft' | 'bottomRight') => {
    // Notification.config({ placement });
    Notification.open({
        title: title,
        content: content,
        type: type,
    });
};