import {URLSearchParamsInit, useLocation, useSearchParams} from "react-router-dom";
import {useCallback, useMemo} from "react";
import {cleanObjectNullValue} from "../utils/util";

export const useSetURLSearchParams = () => {
    const location = useLocation();
    const [urlSearchParamEntries, setUrlSearchParams] = useSearchParams();
    return useCallback((urlSearchParams: Record<string, unknown>, navigateOptions?: {
        replace?: boolean | undefined;
        state?: object | null | undefined;
    } | undefined) => {
        const newUrlSearchParams = Object.assign({}, Object.fromEntries(urlSearchParamEntries), urlSearchParams)
        const urlSearchParamsTrimNull = cleanObjectNullValue(newUrlSearchParams) as Record<string, string | string[]>
        setUrlSearchParams(urlSearchParamsTrimNull, navigateOptions)
    }, [location.pathname])
}

export const useURLSearchParams = <K extends string>(urlSearchParamKeys: K[], defaultInit?: URLSearchParamsInit) => {
    const [urlSearchParamEntries,] = useSearchParams(defaultInit);
    return [
        useMemo(() => {
            return urlSearchParamKeys.reduce((previousValue, key:string) => {
                return {...previousValue, [key]: urlSearchParamEntries.get(key) || ''}
            }, {} as {[key in K]: string})
        }, [urlSearchParamEntries]),
        useSetURLSearchParams
    ] as const
}