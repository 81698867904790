import {useMutation, useQuery, useQueryClient} from "react-query";
import * as clientService from "@/services/admin/application.service";
import {HttpError} from "@/httpclient";
import {openErrorNotification} from "@/utils/notificationUtil";
import {PagedList} from "@/types/core/PagedList";
import {Application} from "@/types/admin/Application";
import {AppQueryRequest} from "@/types/admin/QueryClientRequest";
import {ApplicationSecretUpdateRequest} from "@/types/admin/AppSecretUpdateRequest";

export const PAGE_LIST_CACHE_KEY = "ClientPageList";

export function useFindClientPageList(query: AppQueryRequest) {
    return useQuery<PagedList<Application>, HttpError>([PAGE_LIST_CACHE_KEY, query], function () {
        return clientService.findAppPageList(query);
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}

export function useGetAppById(appId: string | undefined) {
    return useQuery<Application, HttpError>(["GetClientById", appId], () => {
        return clientService.findAppById(appId);
    }, {
        enabled: !!appId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

export function useSaveClient() {
    const queryClient = useQueryClient();
    return useMutation(async function(client: Partial<Application>) {
        return clientService.saveApp(client)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useUpdateClient() {
    const queryClient = useQueryClient();
    return useMutation(async function(client: Partial<Application>) {
        return clientService.updateApp(client)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useUpdateAppSecret(appId: string | undefined) {
    const queryClient = useQueryClient();
    return useMutation(async function(request: ApplicationSecretUpdateRequest) {
        return clientService.updateAppSecret(request)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries(["GetClientById", appId])
        }
    })
}

// 修改状态
export function useToggleClientStatus() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, {appId: String, enabled: boolean}>(async function(params: {appId: String, enabled: boolean}) {
        return clientService.toggleAppStatus(params)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useDeleteClient() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, string>(async function(id: String) {
        return clientService.deleteApp(id)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useFindAllClientsList() {
    return useQuery<Application[], HttpError>(['ALLClientList'], function () {
        return clientService.findAllClientsList();
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}
