import {PagedList} from "@/types/core/PagedList";
import httpClient from "@/utils/httpClient";
import {Application} from "@/types/admin/Application";
import {AppQueryRequest} from "@/types/admin/QueryClientRequest";
import {ApplicationSecretUpdateRequest} from "@/types/admin/AppSecretUpdateRequest";

export function findAllClientsList() {
    return httpClient.get<Application[]>("/api/v1/admin/applications", {
    })
}

export function findAppById(appId: string | undefined) {
    return httpClient.get<Application>(`/api/v1/admin/applications/${appId}`)
}

export async function findAppPageList(query: AppQueryRequest) {
    return httpClient.get<PagedList<Application>>("/api/v1/admin/applications/page", {
        params: query
    })
}

export async function saveApp(app: Partial<Application>) {
    return httpClient.post('/api/v1/admin/applications', {
        data: app
    });
}

export async function updateApp(app: Partial<Application>) {
    return httpClient.put(`/api/v1/admin/applications/${app.appId}`, {
        data: app
    });
}

export async function updateAppSecret(request: ApplicationSecretUpdateRequest) {
    return httpClient.put(`/api/v1/admin/applications/${request.appId}/updateAppSecret`, {
        data: request
    });
}

export async function toggleAppStatus(params: {appId: String, enabled: boolean}) {
    return httpClient.patch(`/api/v1/admin/applications/${params.appId}/toggle/status`, {
        params: params
    });
}

export async function deleteApp(id: String) {
    return httpClient.delete(`/api/v1/admin/applications/${id}`);
}
