import React from "react";
import {AxiosRequestConfig, HttpClient, HttpError} from "../httpclient";
import {resetRoute} from "./util";
import uaaClient from "./uaaClient";

const baseURL = process.env.REACT_APP_BASE_URL;

/*
const errorHandler = (error: ResponseError) => {
    debugger
    if (error.response && error.data) {
        // 请求已发送但服务端返回状态码非 2xx 的响应
        const status = error.data.status;
        if (status === 401) {
            Modal.confirm({
                title: "登录超时，请重新登录",
                content: "点击确定重新登录",
                icon: <ExclamationCircleOutlined />,
                onOk(close) {
                    removeToken()
                    window.location.reload()
                    close()
                },
                onCancel() {
                    console.log('Cancel');
                },
            });
            return
        }

        const errorMessage = error.data.message || error.response.statusText || error.message;
        // console.log(`${status}：${errorMessage}`)
        message.error(errorMessage)

    } else {
        // console.log(error.message)
        // 请求初始化时出错或者没有响应返回的异常
        message.error(error.message)
    }

    throw error

}
*/

const httpClient = new HttpClient({
    baseURL,
    // timeout: 10000,
});

const authRequestInterceptor = (config: AxiosRequestConfig) => {
    // const accessToken = Cookies.get("__UAA_ACCESS_TOKEN");
    const accessToken = uaaClient.retrieveAccessToken();
    if (accessToken) {
        const headers = Object.assign({}, config?.headers, {
            'Authorization': `Bearer ${accessToken}`,
        });
        config.headers = headers;
    }
    return config
};

httpClient.interceptors.request.use(authRequestInterceptor)

httpClient.interceptors.response.use((response) => {
    return response
}, (error: HttpError) => {
    debugger
    if (error.code === 403102) {
        // token已过期，请求刷新token请求
        uaaClient.refreshToken().then(() => {
            // 重新请求
            return httpClient.request(error.axiosError.config);
        }).catch(reason => {
            uaaClient.logout().then(() => {
                resetRoute()
            });
        })
    }
    throw error;
});

export default httpClient