import React from "react";
import DialogContainer from "@/components/DialogContainer";
import {Field, Form, Input, Message, Select, Switch} from "@alifd/next";
import {User} from "@/types/admin/User";
import {openErrorNotification} from "@/utils/notificationUtil";
import * as userModel from "@/models/admin/user.model";
import {HttpError} from "@/httpclient";
import {UserAttribute} from "@/types/admin/UserAttribute";

export interface AddUserAttributeDialogProps {
    userId: string | undefined;
    visible: boolean;
    toggleVisible: () => void;
    onSuccess: () => void;
}

const AddUserAttributeDialog: React.FC<AddUserAttributeDialogProps> = (props) => {
    const {userId, visible, toggleVisible, onSuccess} = props;
    const field = Field.useField();
    const {mutateAsync} = userModel.useSaveUserAttribute();

    function handleOk() {
        field.validate((errors, values) => {
            if (errors) {
                return;
            }
            const userAttribute = field.getValues<Partial<UserAttribute>>();
            userAttribute.userId = userId;
            mutateAsync(userAttribute).then(() => {
                Message.success("添加用户属性成功！");
                onSuccess();
                handleClose()
            }).catch((reason: HttpError) => {
                if (reason.status === 400 && reason.errorResponse?.violations) {
                    const errMsg = reason.errorResponse?.violations[0].message;
                    openErrorNotification("添加用户属性失败", errMsg);
                } else {
                    openErrorNotification("添加用户属性失败", reason.message);
                }
            })
        })
    }

    function handleClose() {
        toggleVisible()
    }

    return (
        <DialogContainer title={'添加用户属性'} visible={visible} onOk={handleOk} onCancel={handleClose} onClose={handleClose}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}} colon={true} field={field}>
                <Form.Item label="属性名称：" required={true}>
                    <Input name={'attributeName'} />
                </Form.Item>
                <Form.Item label="属性值：" required={true}>
                    <Input name="attributeValue" />
                </Form.Item>
            </Form>
        </DialogContainer>
    )
}

export default AddUserAttributeDialog