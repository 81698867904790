import React, {useState} from "react";
import styled from "@emotion/styled";
import logo from '@/assets/logo.svg';
import loginBg from '@/assets/login-bg.jpg'
import bg2 from '@/assets/bg2.png'

import {useAuthz} from "@/contexts/UAAContext";
import {Box, Button, Card, Checkbox, Divider, Form, Grid, Input} from "@alifd/next";

const {Row, Col} = Grid;

const Login: React.FC = () => {
    const {login} = useAuthz();
    const [loading, setLoading] = useState(false)

    const handleSubmit = (values: any, errors: any) => {
        setLoading(true)
        login(values).then(() => {
            console.log('login success')
        }).finally(() => {
            setLoading(false)
        })
    }

    return (
        <Container>
            {/*<Header/>*/}
            <Background/>
            <Row style={{width: '100vw', height: '100vh'}} justify={"center"} align={"center"}>
                <Col span="12" style={{position: "relative", height: '100vh'}}>
                    <Box justify={"center"} align={"center"} direction={"column"} style={{height: '100vh'}}>
                        <div>
                            <img src={logo} alt="logo" style={{height: "6rem"}}/>
                        </div>
                        <div style={{marginTop: '6rem', lineHeight: '1.5', color: 'rgb(52, 58, 64)'}}>
                            <img src={bg2} alt="" style={{width: "100%"}}/>
                        </div>
                    </Box>
                    <VerticalLine/>
                </Col>
                <Col span="12" style={{position: "relative", display: "flex", justifyContent: "center", alignContent: "center"}}>
                    <Box justify={"center"} align={"center"} direction={"column"} style={{width: '85%'}}>
                        {/*<ShadowCard free={true}>*/}
                        <Title>
                            登录
                        </Title>
                        <LoginForm>
                            <Form colon>
                                <Form.Item
                                    label=""
                                    required
                                    requiredMessage="请输入用户名!"
                                >
                                    <Input name="username" placeholder="用户名"/>
                                </Form.Item>
                                <Form.Item
                                    label=""
                                    required
                                    requiredMessage="请输入密码!"
                                >
                                    <Input.Password name="password" placeholder="密码"/>
                                </Form.Item>
                                {/*<Form.Item label=" " colon={false}>
                        <Checkbox name="agreement" defaultChecked>
                            记住我
                        </Checkbox>
                    </Form.Item>*/}
                                <Form.Item label="" colon={false}>
                                    <Form.Submit
                                        loading={loading}
                                        type="primary"
                                        validate
                                        onClick={handleSubmit}
                                        style={{width: "100%"}}
                                    >
                                        登录
                                    </Form.Submit>
                                </Form.Item>
                            </Form>
                        </LoginForm>
                        {/*</ShadowCard>*/}
                    </Box>
                </Col>
            </Row>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    min-height: 100vh;
`

const Background = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url(${loginBg});
    z-index: -1;
`

const Header = styled.header`
    background: url(${logo}) no-repeat center;
    padding: 5rem 0;
    background-size: 8rem auto;
    width: 100%;
`

const VerticalLine = styled.div`
    position: absolute;
    top: 100px;
    bottom: 100px;
    right: 0px;
    background: rgb(255, 255, 255);
    width: 30px;
    box-shadow: rgb(245, 245, 245) -19px 0px 35px -7px;
`

const ShadowCard = styled(Card)`
    width: 40rem;
    min-height: 56rem;
    padding: 3.2rem 4rem;
    border-radius: 0.3rem;
    box-sizing: border-box;
    box-shadow: rgba(0,0,0,0.1) 0 0 10px;
    text-align: center;
`

const LoginForm = styled.div`
    // width: 30rem;
    width: 50%;
`

const Title = styled.h2`
    /*margin-bottom: 2.4rem;
    color: rgb(94, 108, 132);*/
    letter-spacing: 5px;
    font-size: 24px;
    color: #606266;
    margin: 0px auto 2.4rem auto;
    text-align: center;
    font-family:"Hiragino Sans GB";
    font-weight:normal
`

const LongButton = styled(Button)`
    width: 100%;
`

export default Login