import {Resource} from "@/types/admin/Resource";
import httpClient from "@/utils/httpClient";
import {TreeObject} from "@/types/core/TreeObject";
import {AssignedResourcesResponse} from "@/types/admin/AssignedResourcesResponse";

export async function findResourceTree(id: string | undefined) {
    return httpClient.get<TreeObject<Resource>[]>(`/api/v1/admin/resources/tree/${id}`)
}

export async function findResourceById(resourceId: string | undefined) {
    return httpClient.get<Resource>(`/api/v1/admin/resources/${resourceId}`)
}

export async function saveResource(resource: Partial<Resource>) {
    return httpClient.post<Resource>(`/api/v1/admin/resources/${resource.appId}`, {
        data: resource
    });
}

export async function deleteResource(resourceId: string) {
    return httpClient.delete(`/api/v1/admin/resources/${resourceId}`);
}

export async function updateResource(resource: Partial<Resource>) {
    return httpClient.put(`/api/v1/admin/resources/${resource.resourceId}`, {
        data: resource
    });
}

// =============== permission ===============================

export async function findAssignedResourcesByRoleWithAppId(appId: string, roleId: string) {
    return httpClient.get<AssignedResourcesResponse>(`/api/v1/admin/permissions/roles/${roleId}/clients/${appId}`)
}

export async function assignResourcesToRole(roleId: string, resourceIds: string[]) {
    return httpClient.post<Resource>(`/api/v1/admin/permissions/roles/${roleId}/resources`, {
        data: {
            roleId: roleId,
            resourceIds: resourceIds
        }
    });
}
