import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Button,
    Field,
    Form,
    Grid,
    Icon,
    Input,
    Menu,
    Message,
    Radio,
    Search,
    Shell,
    Switch,
    Tree
} from "@alifd/next";
import TabContentContainer from "@/components/TabContentContainer";
import {defaultFormItemLayout} from "@/contants/Constans";
import {TreeObject} from "@/types/core/TreeObject";
import {Resource} from "@/types/admin/Resource";
import * as resourceModel from "@/models/admin/resource.model";
import {loopForContextMenu, loopMatchNodeByLabel, loopParseTreeNode} from "@/utils/TreeUtil";
import {openErrorNotification} from "@/utils/notificationUtil";
import AddResourceDialog from "./AddResourceDialog";
import {useToggle} from "react-use";

export interface ResourceManagementProps {
    appId: string | undefined;
}

const ResourceManagement: React.FC<ResourceManagementProps> = (props) => {
    const {appId} = props;
    // 查询资源树
    const [resourceTreeDataSource, setResourceTreeDataSource] = useState<TreeObject<Resource>[]>([]);
    const {data: resourceTree, isLoading: isLoadingResourceTree} = resourceModel.useFindResourceTree(appId);
    const resourceField = Field.useField({forceUpdate: false});
    const [resourceId, setResourceId] = useState<string>();
    const [addResourceDialogVisible, toggleResourceDialogVisible] = useToggle(false);
    const refreshResourceTree = resourceModel.useRefreshResourceTree();
    const {mutateAsync: deleteResourceAsync} = resourceModel.useDeleteResource();
    const {mutateAsync: updateResourceAsync} = resourceModel.useUpdateResource();

    // 树展开状态
    const [treeExpandState, setExpandTreeState] = useState<{ expandedKeys: string[], autoExpandParent: boolean }>({
        expandedKeys: [],
        autoExpandParent: true
    });
    // 搜索匹配KEYS
    const [matchedKeys, setMatchedKeys] = useState<string[]>([]);
    // 选中节点KEYS
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    // 右键选中节点
    const [rightClickSelectedNode, setRightClickSelectedNode] = useState<TreeObject<Resource> | undefined>(undefined);

    // 查询选中资源
    const {data: selectedResource} = resourceModel.useFindResourceById(resourceId)

    useEffect(() => {
        if (selectedResource) {
            resourceField.setValues(selectedResource)
        }
    }, [selectedResource]);

    useEffect(() => {
        if (resourceTree && resourceTree.length) {
            const tree = loopParseTreeNode(resourceTree, (x) => {
                const newNode = {
                    key: x.key,
                    label: x.label,
                    order: x.order,
                    children: x.children || [],
                    type: x.extra?.resourceType || "LEFT_MENU",
                    isLeaf: x.extra?.resourceType != "TOP_MENU" && x.extra?.resourceType != "LEFT_MENU",
                    icon: x.extra?.icon ? <Icon type={x.extra?.icon}/> : undefined
                } as TreeObject<Resource>;
                return newNode;
            });
            const newTree = loopForContextMenu(tree, createContextMenu);
            setResourceTreeDataSource(newTree)
        }
    }, [resourceTree]);

    // 搜索树节点
    const handleSearchTreeNode = (value: any) => {
        value = value.trim();
        if (!value) {
            setMatchedKeys([])
            return;
        }

        const matchedKeys: string[] = [];
        const loop = (data: TreeObject<Resource>[] | undefined) => {
            if (data && data.length) {
                data.forEach(item => {
                    if (item.label.indexOf(value) > -1) {
                        matchedKeys.push(item.key);
                    }
                    if (item.children && item.children.length) {
                        loop(item.children);
                    }
                });
            }
        }
        loop(resourceTreeDataSource);

        setExpandTreeState({
            expandedKeys: [...matchedKeys],
            autoExpandParent: true
        });
        setMatchedKeys(matchedKeys);
    }

    // 展开树
    const handleExpandTree = (expandedKeys: string[], extra: Object) => {
        setExpandTreeState({
            expandedKeys: expandedKeys,
            autoExpandParent: false
        });
    };

    const filterTreeNode = (node: any): boolean => {
        return (
            matchedKeys && (matchedKeys.indexOf(node.props.eventKey) > -1)
        );
    };

    // 选择树节点
    const handleSelectTreeNode = (selectedKeys: string[], extra: Object) => {
        const selectedKey = selectedKeys[0];
        setResourceId(selectedKey);
        /*const selectedTreeNode = loopMatchNodeByKey(resourceTreeDataSource, selectedKey);
        if (selectedTreeNode && selectedTreeNode.type === TreeNodeType.JOB) {
            const jobName = selectedTreeNode.label;
            const jobId = selectedTreeNode.key;
            addTabItem(jobId, jobName, selectedTreeNode.extra as JobInfo);
        }*/
        setSelectedKeys(selectedKeys);
    }

    // 创建右键菜单
    const createContextMenu = (e: any, tree: TreeObject<Resource>[]) => {
        e.preventDefault();
        const target = e.target;
        const {top, left} = target.getBoundingClientRect();
        const label = target.ariaLabel || target.innerHTML;
        const selectNode = loopMatchNodeByLabel(tree, label);
        if (!selectNode) {
            return;
        }
        const key = selectNode.key;
        // 设置当前右键选中节点
        setRightClickSelectedNode(selectNode);

        if (selectNode.type === "TOP_MENU" || selectNode.type === "LEFT_MENU") {
            Menu.create({
                target: e.target,
                offset: [e.clientX - left, e.clientY - top],
                className: "context-menu",
                popupClassName: "context-menu",
                onItemClick: console.log,
                // selectedKeys: selectedKeys,
                selectMode: "single",
                // onSelect: handleSelectContextMenu,
                children: [
                    <Menu.Item key="21" onClick={() => handleAddResource(key)}>新建资源</Menu.Item>,
                    <Menu.Item key="23" onClick={() => handleRenameResource(key)}>重命名</Menu.Item>,
                    <Menu.Item key="24" onClick={() => deleteResource(key)}>删除资源</Menu.Item>
                ]
            });
        } else {
            Menu.create({
                target: e.target,
                offset: [e.clientX - left, e.clientY - top],
                className: "context-menu",
                popupClassName: "context-menu",
                onItemClick: console.log,
                // selectedKeys: selectedKeys,
                selectMode: "multiple",
                // onSelect: handleSelectContextMenu,
                children: [
                    <Menu.Item key="51" onClick={() => {
                        handleRenameResource(key)
                    }}>重命名资源</Menu.Item>,
                    <Menu.Item key="52" onClick={() => {
                        deleteResource(key)
                    }}>删除任务</Menu.Item>
                ]
            });
        }
    };


    // 右键菜单处理
    /*const handleSelectContextMenu = (selectedKeys: string[]) => {
        selectedKeys = selectedKeys.filter(key => {
            return ["sub-1", "sub-2"].indexOf(key) > -1;
        });
        setSelectedKeys(selectedKeys)
    }*/

    // 添加资源
    const handleAddResource = (parentId: string | undefined) => {
        if (!parentId) {
            setRightClickSelectedNode(undefined);
        }
        toggleResourceDialogVisible()
    }
    // 重命名资源
    const handleRenameResource = (key: string) => {
    }
    // 删除资源
    const deleteResource = (key: string) => {
        deleteResourceAsync(key).then(() => {
            Message.success("删除成功！")
        }).catch(reason => {
            openErrorNotification("删除失败", reason.message)
        })
    }

    // 添加资源成功回调
    const onAddResourceSuccessCallback = useCallback(async (resourceId: string) => {
        await refreshResourceTree();
        const keys = [resourceId];
        setSelectedKeys(keys);
        // 展开
        setExpandTreeState({
            expandedKeys: [...keys],
            autoExpandParent: true
        });
        setResourceId(resourceId);
    }, [appId]);

    // 更新资源信息
    function handleSubmitResourceInfo() {
        resourceField.validate((errors, values) => {
            if (errors) {
                return;
            }
            const resource = resourceField.getValues<Partial<Resource>>();
            updateResourceAsync(resource).then(() => {
                Message.success("更新成功！");
            }).catch(reason => {
                openErrorNotification("更新失败", reason.message);
            })
        })
    }

    return (
        <div>
            <Shell
                className={"iframe-hack"}
                device={"desktop"}
                style={{}}
            >

                <Shell.LocalNavigation style={{backgroundColor: '#fff'}}>

                    <Box direction={"column"} style={{padding: '1rem'}}>
                        <Box direction={"row"} justify={"space-between"} align={"baseline"} spacing={4}>
                            <Search
                                shape="simple"
                                size="medium"
                                style={{marginBottom: "10px"}}
                                onChange={handleSearchTreeNode}
                            />
                            <Icon type="add" size={"xs"} onClick={() => {
                                handleAddResource(undefined)
                            }}/>
                            <Icon type="refresh" size={"xs"} onClick={async () => {
                                await refreshResourceTree()
                            }}/>
                        </Box>

                        <Tree
                            selectedKeys={selectedKeys}
                            expandedKeys={treeExpandState.expandedKeys}
                            autoExpandParent={treeExpandState.autoExpandParent}
                            filterTreeNode={filterTreeNode}
                            onExpand={handleExpandTree}
                            onSelect={handleSelectTreeNode}
                            multiple={false}
                            editable={false}
                            dataSource={resourceTreeDataSource}
                        />

                    </Box>

                </Shell.LocalNavigation>

                <Shell.Content style={{backgroundColor: '#fff'}}>
                    <TabContentContainer>
                        {
                            !resourceId ? <>点击左边资源查看详情</> : <div style={{minWidth: '60rem'}}>
                                <Form {...defaultFormItemLayout} colon={true} field={resourceField}>
                                    <Form.Item label="ID" required={true} isPreview={true}>
                                        <Input name={'resourceId'}/>
                                    </Form.Item>
                                    {/*<Form.Item label="父级" isPreview={true}>
                                                <Input name="parentId"/>
                                            </Form.Item>*/}
                                    <Form.Item label="资源名称" required={true}>
                                        <Input name="resourceName"/>
                                    </Form.Item>
                                    <Form.Item label="显示名称" required={true}>
                                        <Input name="displayName"/>
                                    </Form.Item>
                                    <Form.Item label="资源类型" required={true}>
                                        <Radio.Group shape="button" name={"resourceType"}>
                                            <Radio value="TOP_MENU">顶部菜单</Radio>
                                            <Radio value="LEFT_MENU">左菜单</Radio>
                                            <Radio value="BUTTON">按钮</Radio>
                                            <Radio value="LINK">链接</Radio>
                                            <Radio value="API">API</Radio>
                                            <Radio value="IMAGE">图片</Radio>
                                            <Radio value="TEXT">文本</Radio>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item label="资源路径">
                                        <Input name="resourceUri"/>
                                    </Form.Item>
                                    <Form.Item label="路由组件">
                                        <Input name="routeElement"/>
                                    </Form.Item>
                                    <Form.Item label="路由路径">
                                        <Input name="routePath"/>
                                    </Form.Item>
                                    <Form.Item label="权限码">
                                        <Input name="authorityCode"/>
                                    </Form.Item>
                                    <Form.Item label="是否打开新页面">
                                        <Switch name="isOpen"/>
                                    </Form.Item>
                                    <Form.Item label="图标">
                                        <Input name="icon"/>
                                    </Form.Item>
                                    <Form.Item label="排序号">
                                        <Input name="sortNo"/>
                                    </Form.Item>
                                    <Form.Item label="是否启用">
                                        <Switch name="enabled"/>
                                    </Form.Item>
                                </Form>

                                <Grid.Row>
                                    <Grid.Col span="6"></Grid.Col>
                                    <Grid.Col span="18">
                                        <Button type={"primary"} onClick={handleSubmitResourceInfo}>提交</Button>
                                    </Grid.Col>
                                </Grid.Row>
                            </div>
                        }
                    </TabContentContainer>
                </Shell.Content>
            </Shell>

            <AddResourceDialog visible={addResourceDialogVisible} toggleVisible={toggleResourceDialogVisible}
                               appId={appId} parent={rightClickSelectedNode} onSuccess={onAddResourceSuccessCallback}/>

        </div>
    )
}

export default ResourceManagement
