import React from "react";
import Exception from "@/components/Exception";
import NotFountImage from '@/assets/notfound-200-200.png'

const NotFound = () => {
    return (
        <Exception
            statusCode="404"
            image={NotFountImage}
            description="服务器好像挂了你要等会了"
        />
    )
}

export default NotFound