import React from "react";
import DialogContainer from "@/components/DialogContainer";
import {Field, Form, Input, Message, Select, Switch} from "@alifd/next";
import {User} from "@/types/admin/User";
import {openErrorNotification} from "@/utils/notificationUtil";
import {useSaveUser} from "@/models/admin/user.model";
import {HttpError} from "@/httpclient";

export interface AddUserDialogProps {
    visible: boolean;
    toggleVisible: () => void;
    onSuccess: () => void;
}

const AddUserDialog: React.FC<AddUserDialogProps> = (props) => {
    const {visible, toggleVisible, onSuccess} = props
    const field = Field.useField();
    const {mutateAsync} = useSaveUser();

    function handleOk() {
        field.validate((errors, values) => {
            if (errors) {
                return;
            }
            const user = field.getValues<Partial<User>>();
            mutateAsync(user).then(() => {
                Message.success("新建用户成功！");
                onSuccess();
                handleClose()
            }).catch((reason: HttpError) => {
                if (reason.status === 400 && reason.errorResponse?.violations) {
                    const errMsg = reason.errorResponse?.violations[0].message;
                    openErrorNotification("新建用户失败", errMsg);
                } else {
                    openErrorNotification("新建用户失败", reason.message);
                }
            })
        })
    }

    function handleClose() {
        toggleVisible()
    }

    return (
        <DialogContainer title={'添加用户'} visible={visible} onOk={handleOk} onCancel={handleClose} onClose={handleClose}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}} colon={true} field={field}>
                <Form.Item label="用户名" required={true}>
                    <Input name={'username'} />
                </Form.Item>
                <Form.Item label="密码" required={true}>
                    <Input.Password name={'password'} />
                </Form.Item>
                <Form.Item label="确认密码" required={true}>
                    <Input.Password name="matchPassword" />
                </Form.Item>
                <Form.Item label="昵称" required={true}>
                    <Input name="nickname" />
                </Form.Item>
                <Form.Item label="是否启用">
                    <Switch name="enabled" />
                </Form.Item>
                {/*<Form.Item label="姓名" >
                    <Input name="name" />
                </Form.Item>
                <Form.Item label="邮箱" >
                    <Input name="email" />
                </Form.Item>
                <Form.Item label="手机号" >
                    <Input name="mobile" />
                </Form.Item>
                <Form.Item label="头像" >
                    <Input name="avatar" />
                </Form.Item>
                <Form.Item label="是否启用多因子验证流程" >
                    <Input name="usingMfa" />
                </Form.Item>
                */}
            </Form>
        </DialogContainer>
    )
}

export default AddUserDialog