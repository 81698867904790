import React from "react";
import DialogContainer from "@/components/DialogContainer";
import {Field, Form, Input, Message, Radio} from "@alifd/next";
import {openErrorNotification} from "@/utils/notificationUtil";
import * as resourceModel from "@/models/admin/resource.model";
import {Resource} from "@/types/admin/Resource";
import {TreeObject} from "@/types/core/TreeObject";

export interface AddResourceDialogProps {
    visible: boolean;
    toggleVisible: () => void;
    appId: string | undefined;
    parent: TreeObject<Resource> | undefined;
    onSuccess: (resourceId: string) => void;
}

const AddResourceDialog: React.FC<AddResourceDialogProps> = (props) => {
    const {visible, toggleVisible, appId, parent, onSuccess} = props;
    const field = Field.useField();
    const {mutateAsync} = resourceModel.useSaveResource();
    function handleOk() {
        field.validate((errors, values) => {
            if (errors) {
                return;
            }
            const resource = field.getValues<Partial<Resource>>();
            if (!appId) {
                Message.error("appId参数不能为空！");
                return;
            }
            if (parent) {
                resource.parentId = parent.key;
            }
            resource.appId = appId;
            mutateAsync(resource).then((value) => {
                Message.success("添加资源成功！");
                onSuccess(value.resourceId);
                field.reset();
                handleClose();
            }).catch(reason => {
                openErrorNotification("添加资源失败", reason.message);
            })
        })
    }

    function handleClose() {
        toggleVisible()
    }

    return (
        <DialogContainer title={'添加资源'} visible={visible} onOk={handleOk} onCancel={handleClose} onClose={handleClose}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}} colon={true} field={field}>
                {
                    parent?<Form.Item label="父级" required={true} isPreview={true} aria-disabled={"true"}>
                        <Input name="parentName" value={parent?.label} disabled={true}/>
                    </Form.Item>:<></>
                }
                <Form.Item label="资源类型" required={true}>
                    <Radio.Group shape="button" name={"resourceType"} defaultValue={'LEFT_MENU'}>
                        <Radio value="TOP_MENU">顶部菜单</Radio>
                        <Radio value="LEFT_MENU">左菜单</Radio>
                        <Radio value="BUTTON">按钮</Radio>
                        <Radio value="LINK">链接</Radio>
                        <Radio value="API">API</Radio>
                        <Radio value="IMAGE">图片</Radio>
                        <Radio value="TEXT">文本</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="资源名称" required={true}>
                    <Input name="resourceName"/>
                </Form.Item>
                <Form.Item label="显示名称" required={true}>
                    <Input name="displayName"/>
                </Form.Item>
                <Form.Item label="排序号" required={true}>
                    <Input name="sortNo"/>
                </Form.Item>
                <Form.Item label="权限码">
                    <Input name="authorityCode"/>
                </Form.Item>
            </Form>
        </DialogContainer>
    )
}

export default AddResourceDialog
