import React from "react";
import {Card} from "@alifd/next";
import styles from './index.module.css';

export interface ExceptionProps {
    statusCode: string;
    description: string;
    image: string;
}

const Exception: React.FC<ExceptionProps> = (props) => {
    const {statusCode, description, image} = props;

    return (
        <Card free className={styles.exception}>
            <div>
                <img src={image} className={styles.exceptionImage} alt="img"/>
                <h1 className={styles.statuscode}>{statusCode}</h1>
                <div className={styles.description}>{description}</div>
            </div>
        </Card>
    );
}

export default Exception