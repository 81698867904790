import React from "react";
import {Table, Tag} from "@alifd/next";
import {User} from "@/types/admin/User";
import dayjs from "dayjs";
import * as groupModel from "@/models/admin/group.model"

export interface GroupMemberListProps {
    groupId: string | undefined;
}

const result = [
    {
        id: "002",
        name: 1925,
        value: "the great gatsby"
    },
    {
        id: "003",
        name: 1719,
        value: "The adventures of Robinson Crusoe"
    }
];

const GroupMemberList: React.FC<GroupMemberListProps> = (props) => {
    const {groupId} = props;
    const {data, isLoading} = groupModel.useFindGroupMemberListByGroupId(groupId)
    return (
        <Table dataSource={data} loading={isLoading}>
            <Table.Column title="User ID" dataIndex="userId" />
            <Table.Column title="用户名" dataIndex="username" />
            <Table.Column title="显示名称" dataIndex="displayName" />
            {/*<Table.Column title="姓名" dataIndex="name" />
                <Table.Column title="电子邮件" dataIndex="email" />
                <Table.Column title="手机号码" dataIndex="mobile" />*/}
            <Table.Column title="头像" dataIndex="avatar" />
            <Table.Column title="多因子认证" cell={(value: any, index: number, record: User) => {
                if (record.usingMfa) {
                    return <Tag type={"primary"} size={"small"} color={"green"}>启用</Tag>
                } else {
                    return <Tag type={"primary"} size={"small"}>禁用</Tag>
                }
            }} />

            <Table.Column title="是否启用" cell={(value: any, index: number, record: User) => {
                if (record.enabled) {
                    return <Tag type={"primary"} size={"small"} color={"#03c1fd"}>启用</Tag>
                } else {
                    return <Tag type={"primary"} size={"small"}>禁用</Tag>
                }
            }} />
            <Table.Column title="创建时间" dataIndex="createdTime" cell={(value: any, index: number, record: User) => {
                return <div>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</div>
            }} />
        </Table>
    )
}

export default GroupMemberList