import {ErrorResponse} from "./types";

export const isErrorResponse = (value: any): value is ErrorResponse => {
    return value?.code && value?.message
}

export const createObjectURL = function(blob: Blob){
    return window[window.webkitURL ? 'webkitURL' : 'URL']['createObjectURL'](blob);
};

export const blobToJson = (blob: Blob): Promise<ErrorResponse> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = res => {
            const target = res.target // 得到字符串
            const jsonStr = target?.result as string
            const data = JSON.parse(jsonStr) // 解析成json对象
            resolve(data)
        } // 成功回调
        reader.onerror = err => {
            reject(err)
        } // 失败回调
        reader.readAsText(new Blob([blob]), 'utf-8') // 按照utf-8编码解析
    })
}