import React, {useEffect, useState} from "react";
import {
    Box,
    Breadcrumb,
    Button,
    Field,
    Form,
    Grid,
    Icon,
    Input,
    Loading,
    Message,
    Switch,
    Tab,
    Table,
    Tag,
    Transfer,
    Tree
} from "@alifd/next";
import {PageContainer} from "@/components/lib";
import {useURLPathParams} from "@/hooks/useURLPathParams";
import * as userModel from "@/models/admin/user.model";
import {defaultFormItemLayout} from "@/contants/Constans";
import {Application} from "@/types/admin/Application";
import {openErrorNotification} from "@/utils/notificationUtil";
import {User} from "@/types/admin/User";
import TabContentContainer from "@/components/TabContentContainer";
import {TransferItem} from "@/types/core/TransferItem";
import UserAttributeList from "./components/UserAttributeList";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {HttpError} from "@/httpclient";
import {useURLSearchParams} from "@/hooks/useURLSearchParams";
import {UpdateUserPasswordRequest} from "@/types/admin/UpdateUserPasswordRequest";
import AssignGroupToUser from "./components/AssignGroupToUser";

const UserDetail: React.FC = (props) => {
    const navigate = useNavigate();
    const pathParams = useURLPathParams("/admin/user-management/:userId", ["userId"]);
    const userId = pathParams?.userId;
    const [{nickname}] = useURLSearchParams(["nickname"]);

    const {data: userInfo, isLoading} = userModel.useGetUserById(userId);
    const {mutateAsync: updateUserMutateAsync} = userModel.useUpdateUser();
    const {mutateAsync: deleteRoleFn} = userModel.useDeleteUser();
    const {mutateAsync: updatePassword} = userModel.useUpdatePassword()

    // user info
    const userInfoField = Field.useField({forceUpdate: false});
    const updatePasswordField = Field.useField();

    // 查找已分配角色
    const [assignedRoleIds, setAssignedRoleIds] = useState<string[]>([]);
    const {data: roleTransferResponse, isLoading: isRoleTransferLoading}= userModel.useFindRoleTransferByUserId(userId);

    // 查找已分配应用
    const [assignedAppIds, setAssignedAppIds] = useState<string[]>([]);
    const {data: myApps, isLoading: isLoadingFindMyApp}= userModel.useFindClientsByUserId(userId);

    useEffect(() => {
        userInfoField.setValues(userInfo)
    }, [userInfo]);

    useEffect(() => {
        setAssignedRoleIds(roleTransferResponse?.keys || [])
    }, [roleTransferResponse]);

    function handleSubmitUserInfo() {
        userInfoField.validate((errors, values) => {
            if (errors) {
                return;
            }
            const user = userInfoField.getValues<Partial<User>>();
            updateUserMutateAsync(user).then(() => {
                Message.success("更新成功！");
            }).catch(reason => {
                openErrorNotification("更新失败", reason.message);
            })
        })
    }

    function handleUpdatePassword() {
        updatePasswordField.validate((errors, values) => {
            if (errors) {
                return;
            }
            const updateUserPasswordRequest = updatePasswordField.getValues<UpdateUserPasswordRequest>();
            updateUserPasswordRequest.userId = String(userId);
            debugger
            updatePassword(updateUserPasswordRequest).then(() => {
                Message.success("更新成功！");
                updatePasswordField.reset()
            }).catch(reason => {
                openErrorNotification("更新失败", reason.message);
            })
        })
    }

    function handleChangeRole(value: Array<any>, data: Array<any>, extra: {}) {
        setAssignedRoleIds(value)
    }

    // 删除
    function handleDelete() {
        if (userId) {
            deleteRoleFn(userId).then(() => {
                Message.success("删除成功！");
                navigate(-1);
            }).catch((reason: HttpError) => {
                openErrorNotification("删除失败", reason.message)
            })
        }
    }

    return isLoading ? (<Loading fullScreen={true}/>) : (
        <PageContainer>
            <Breadcrumb>
                <Breadcrumb.Item link="/admin/user-management">用户管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <b>{nickname}</b>
                </Breadcrumb.Item>
            </Breadcrumb>

            <Box direction={"row"} justify={"flex-start"} align={"center"} spacing={10} style={{marginTop: '1rem'}}>
                {/*<Icon size={"xs"} type="arrow-left" />*/}
                <label>{nickname}</label>
                <Icon size={"small"} type="ashbin" onClick={handleDelete} role={"button"} aria-label="icon ashbin" style={{cursor: "pointer" }} />
            </Box>

            <Tab>
                <Tab.Item title="用户信息" key="1">
                    <TabContentContainer>
                        <div style={{minWidth: '60rem'}}>
                            <Form {...defaultFormItemLayout} colon={true} field={userInfoField}>
                                <Form.Item label="用户名" required={true} isPreview={true}>
                                    <Input name={'username'} />
                                </Form.Item>
                                <Form.Item label="昵称" required={true}>
                                    <Input name="nickname"/>
                                </Form.Item>
                                <Form.Item label="姓名">
                                    <Input name="name"/>
                                </Form.Item>
                                {/*<Form.Item label="性别">
                                    <Input name="gender"/>
                                </Form.Item>*/}
                                <Form.Item label="邮箱">
                                    <Input name="email"/>
                                </Form.Item>
                                <Form.Item label="手机号">
                                    <Input name="mobile"/>
                                </Form.Item>
                                <Form.Item label="头像">
                                    <Input name="avatar"/>
                                </Form.Item>
                                <Form.Item label="是否启用多因子验证流程">
                                    <Switch name="usingMfa"/>
                                </Form.Item>
                                <Form.Item label="账号未过期">
                                    <Switch name="accountNonExpired"/>
                                </Form.Item>
                                <Form.Item label="账号未锁定">
                                    <Switch name="accountNonLocked"/>
                                </Form.Item>
                                <Form.Item label="凭证未过期">
                                    <Switch name="credentialsNonExpired"/>
                                </Form.Item>
                                <Form.Item label="是否启用">
                                    <Switch name="enabled"/>
                                </Form.Item>
                            </Form>

                            <Grid.Row>
                                <Grid.Col span="8"></Grid.Col>
                                <Grid.Col span="16">
                                    <Button type={"primary"} onClick={handleSubmitUserInfo}>提交</Button>
                                </Grid.Col>
                            </Grid.Row>
                        </div>
                    </TabContentContainer>
                </Tab.Item>
                <Tab.Item title="用户属性" key="2">
                    <TabContentContainer style={{paddingTop: '0'}}>
                        <UserAttributeList userId={userId} />
                    </TabContentContainer>
                </Tab.Item>
                <Tab.Item title="设置密码" key="3">
                    <TabContentContainer>
                        <div style={{minWidth: '40rem'}}>
                            <Form labelCol={{span: 6}} wrapperCol={{span: 18}} colon={true} field={updatePasswordField}>
                                <Form.Item label="密码" required={true}>
                                    <Input.Password name="password" />
                                </Form.Item>
                                <Form.Item label="确认密码" required={true}>
                                    <Input.Password name="matchPassword"/>
                                </Form.Item>
                            </Form>
                            <Grid.Row>
                                <Grid.Col span="6"></Grid.Col>
                                <Grid.Col span="18">
                                    <Button type={"primary"} onClick={handleUpdatePassword}>设置密码</Button>
                                </Grid.Col>
                            </Grid.Row>
                        </div>
                    </TabContentContainer>
                </Tab.Item>
                <Tab.Item title="用户角色" key="4">
                    <TabContentContainer>
                        <Transfer
                            value={assignedRoleIds}
                            dataSource={roleTransferResponse?.dataSource}
                            defaultLeftChecked={[]}
                            onChange={handleChangeRole}
                            titles={["未选择角色", "已选择角色"]}
                        />
                    </TabContentContainer>
                </Tab.Item>
                <Tab.Item title="用户组" key="5">
                    <TabContentContainer>
                        <AssignGroupToUser userId={String(userId)} />
                    </TabContentContainer>
                </Tab.Item>
                <Tab.Item title="我的应用" key="6">
                    <TabContentContainer>
                        <Table dataSource={myApps} loading={isLoadingFindMyApp}>
                            <Table.Column title="APP ID" dataIndex="appId"/>
                            <Table.Column title="显示名称" dataIndex="displayName"/>
                            <Table.Column title="APP KEY" dataIndex="appKey"/>
                            <Table.Column title="客户端类型" cell={(value: any, index: number, record: Application) => {
                                switch (record.appType) {
                                    case "web":
                                        return <Tag type={"primary"} size={"small"}>web应用</Tag>
                                        break;
                                    case "app":
                                        return <Tag type={"primary"} size={"small"}>app</Tag>
                                        break;
                                    case "frontend":
                                        return <Tag type={"primary"} size={"small"}>前端</Tag>
                                        break;
                                    default:
                                        return <Tag type={"primary"} size={"small"}>未知</Tag>
                                }
                            }}/>
                            <Table.Column title="客户端描述" dataIndex="description"/>
                            <Table.Column title="是否启用" cell={(value: any, index: number, record: Application) => {
                                if (record.enabled) {
                                    return <Tag type={"primary"} size={"small"} color={"#03c1fd"}>启用</Tag>
                                } else {
                                    return <Tag type={"primary"} size={"small"}>禁用</Tag>
                                }
                            }}/>
                            <Table.Column title="创建时间" cell={(value: any, index: number, record: Application) => {
                                return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
                            }} dataIndex="createdTime"/>
                        </Table>
                    </TabContentContainer>
                </Tab.Item>
            </Tab>
        </PageContainer>
    )
}

export default UserDetail
