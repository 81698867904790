import React, {useEffect, useState} from "react";
import {Message, Transfer} from "@alifd/next";
import * as groupModel from "@/models/admin/group.model";
import {TransferItem} from "@/types/core/TransferItem";
import {HttpError} from "@/httpclient";
import {openErrorNotification} from "@/utils/notificationUtil";

export interface AssignRoleForGroupProps {
    groupId: string;
}

const AssignRoleForGroup: React.FC<AssignRoleForGroupProps> = (props) => {
    const {groupId} = props;
    // 查找已分配角色
    const [assignedRoleIds, setAssignedRoleIds] = useState<string[]>([]);
    const {data: roleTransferResponse, isLoading: isRoleTransferLoading} = groupModel.useFindAssignedGroupRolesByGroupId(groupId);

    // 保存分配角色给组
    const {mutateAsync: assignRolesToGroup} = groupModel.useAssignRolesToGroup(groupId);

    useEffect(() => {
        setAssignedRoleIds(roleTransferResponse?.keys || [])
    }, [roleTransferResponse]);

    function handleChangeRole(value: string[], data: TransferItem[], extra: {}) {
        assignRolesToGroup(value).then(() => {
            Message.success("添加成功");
            setAssignedRoleIds(value)
        }).catch((reason: HttpError) => {
            openErrorNotification(reason.status, reason.message);
        })
    }

    return (
        <Transfer
            value={assignedRoleIds}
            dataSource={roleTransferResponse?.dataSource}
            defaultLeftChecked={[]}
            onChange={handleChangeRole}
            titles={["未选择角色", "已选择角色"]}
        />
    )
}
export default AssignRoleForGroup