import React from "react";
import styled from "@emotion/styled";
import {Box} from "@alifd/next";

const Welcome = () => {
    return (
        <ContentContainer justify={"center"} align={"center"}>
            欢迎使用UAA
        </ContentContainer>
    )
}

const ContentContainer = styled(Box)`
    min-height: calc(100vh - 54px);
    flex: 1;
    background-color: #fff;
`

export default Welcome