import React from "react";
import DialogContainer from "@/components/DialogContainer";
import {Field, Form, Input, Message} from "@alifd/next";
import {openErrorNotification} from "@/utils/notificationUtil";
import * as groupModel from "@/models/admin/group.model";
import {HttpError} from "@/httpclient";
import {GroupAttribute} from "@/types/admin/GroupAttribute";

export interface AddGroupAttributeDialogProps {
    groupId: string | undefined;
    visible: boolean;
    toggleVisible: () => void;
    onSuccess: () => void;
}

const AddGroupAttributeDialog: React.FC<AddGroupAttributeDialogProps> = (props) => {
    const {groupId, visible, toggleVisible, onSuccess} = props;
    const field = Field.useField();
    const {mutateAsync} = groupModel.useSaveGroupAttribute();

    function handleOk() {
        field.validate((errors, values) => {
            if (errors) {
                return;
            }
            const groupAttribute = field.getValues<Partial<GroupAttribute>>();
            groupAttribute.groupId = groupId;
            mutateAsync(groupAttribute).then(() => {
                Message.success("添加用户组属性成功！");
                onSuccess();
                handleClose()
            }).catch((reason: HttpError) => {
                if (reason.status === 400 && reason.errorResponse?.violations) {
                    const errMsg = reason.errorResponse?.violations[0].message;
                    openErrorNotification("添加用户组属性失败", errMsg);
                } else {
                    openErrorNotification("添加用户组属性失败", reason.message);
                }
            })
        })
    }

    function handleClose() {
        toggleVisible()
    }

    return (
        <DialogContainer title={'添加用户组属性'} visible={visible} onOk={handleOk} onCancel={handleClose} onClose={handleClose}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}} colon={true} field={field}>
                <Form.Item label="属性名称：" required={true}>
                    <Input name={'attributeName'} />
                </Form.Item>
                <Form.Item label="属性值：" required={true}>
                    <Input name="attributeValue" />
                </Form.Item>
            </Form>
        </DialogContainer>
    )
}

export default AddGroupAttributeDialog