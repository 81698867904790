import httpClient from "@/utils/httpClient";
import {Role} from "@/types/admin/Role";
import {PagedList} from "@/types/core/PagedList";
import {QueryRoleRequest} from "@/types/admin/QueryRoleRequest";

export async function findRolePageList(/*current: number, pageSize: number*/ query: QueryRoleRequest) {
    return httpClient.get<PagedList<Role>>("/api/v1/admin/roles/page", {
        params: query
    })
}

export async function saveRole(role: Partial<Role>) {
    return httpClient.post('/api/v1/admin/roles', {
        data: role
    });
}

export function findRoleById(roleId: string | undefined) {
    return httpClient.get<Role>(`/api/v1/admin/roles/${roleId}`)
}

export async function updateRole(role: Partial<Role>) {
    return httpClient.put(`/api/v1/admin/roles/${role.roleId}`, {
        data: role
    });
}

export async function toggleStatus(params: {id: String, enabled: boolean}) {
    return httpClient.patch(`/api/v1/admin/roles/${params.id}/toggle/status`, {
        params: params
    });
}

export async function deleteRole(roleId: String) {
    return httpClient.delete(`/api/v1/admin/roles/${roleId}`);
}

export async function listAllRoles() {
    return httpClient.get<Role[]>("/api/v1/admin/roles", {
        params: {
        }
    })
}