import React, {useState} from "react";
import {Box, Button, Divider, Form, Input, Message, Pagination, Select, Table, Tag} from "@alifd/next";
import {Link} from "react-router-dom";
import {PageContainer, PaginationLBContainer, PaginationTotal} from "@/components/lib";
import * as clientModel from "@/models/admin/client.model";
import AddClientDialog from "./components/AddClientDialog";
import {useToggle} from "react-use";
import {Application} from "@/types/admin/Application";
import {AppQueryRequest} from "@/types/admin/QueryClientRequest";
import {HttpError} from "@/httpclient";
import {openErrorNotification} from "@/utils/notificationUtil";
import dayjs from "dayjs";

const ClientManagement = () => {
    const [query, setQuery] = useState<AppQueryRequest>({current: 0, pageSize: 10})
    const {data: pageList, isLoading} = clientModel.useFindClientPageList(query);
    const {mutateAsync: toggleClientStatus} = clientModel.useToggleClientStatus();
    const {mutateAsync: deleteClientFn} = clientModel.useDeleteClient();

    const [addDialogVisible, toggleDialogVisible] = useToggle(false);

    function renderAction(value: any, index: number, record: Application) {

        return <Box direction={"row"} justify={"flex-start"} align={"center"}>
            <Button type={"primary"} text={true}>
                <Link to={`/admin/client-management/${record.appId}?displayName=${record.displayName}`}>编辑</Link>
            </Button>
            <Divider direction={"ver"}/>
            <Button type={"primary"} text={true}
                    onClick={() => handleToggleClientStatus(record.appId, !record.enabled)}>
                {
                    record.enabled?"禁用":"启用"
                }
            </Button>
            <Divider direction={"ver"}/>
            <Button type={"primary"} text={true} onClick={() => handleDelete(record.appId)}>删除</Button>
            {/*<Divider direction={"ver"}/>
            <Button text={true}>
                <Link to={`/admin/client-management/${record.appId}?displayName=${record.displayName}`}>详情</Link>
            </Button>*/}
        </Box>
    }

    function onAddSuccess() {
    }

    // 修改状态
    function handleToggleClientStatus(id: string, enabled: boolean) {
        toggleClientStatus({
            appId: id,
            enabled
        }).then(() => {
            Message.success("状态更新成功！");
        }).catch((reason: HttpError) => {
            openErrorNotification("状态更新失败", reason.message)
        })
    }

    // 删除
    function handleDelete(id: string) {
        deleteClientFn(id).then(() => {
            Message.success("删除成功！");
        }).catch((reason: HttpError) => {
            openErrorNotification("删除失败", reason.message)
        })
    }

    // 搜索
    const handleSubmitQuery = (values: AppQueryRequest, errors: any) => {
        setQuery({
            ...query,
            ...values
        })
    };
    // 排序
    const handleSort = (dataIndex: string, direction: string) => {
        debugger
    }

    return (
        <PageContainer>
            <Box direction={"row"} justify={"space-between"} align={"center"}>
                <label>应用管理</label>
                <Box direction={"row"} spacing={10}>
                    <Button type={"primary"} onClick={toggleDialogVisible}>添加应用</Button>
                    <Button>刷新</Button>
                </Box>
            </Box>
            <div>
                <Form inline={true}>
                    <Form.Item label="应用状态">
                        <Select name={"enabled"}>
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value="1">启用</Select.Option>
                            <Select.Option value="0">禁用</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="应用类型">
                        <Select name={"appType"}>
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value="web">web应用</Select.Option>
                            <Select.Option value="app">app</Select.Option>
                            <Select.Option value="frontend">前端</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="应用名称">
                        <Input name={"displayName"}/>
                    </Form.Item>
                    <Form.Submit type={"primary"} onClick={handleSubmitQuery}>搜索</Form.Submit>
                    <Form.Reset style={{marginLeft: "1rem"}}>清除</Form.Reset>
                </Form>
            </div>
            <Table dataSource={pageList?.data} loading={isLoading} onSort={handleSort}>
                <Table.Column title="APP ID" dataIndex="appId"/>
                <Table.Column title="显示名称" dataIndex="displayName"/>
                <Table.Column title="客户端类型" cell={(value: any, index: number, record: Application) => {
                    switch (record.appType) {
                        case "web":
                            return <Tag type={"primary"} size={"small"}>web应用</Tag>
                            break;
                        case "app":
                            return <Tag type={"primary"} size={"small"}>app</Tag>
                            break;
                        case "frontend":
                            return <Tag type={"primary"} size={"small"}>前端</Tag>
                            break;
                        default:
                            return <Tag type={"primary"} size={"small"}>未知</Tag>
                    }
                }}/>
                <Table.Column title="APP KEY" dataIndex="appKey"/>
                <Table.Column title="客户端描述" dataIndex="description"/>
                <Table.Column title="是否在控制台显示" dataIndex="appType" cell={(value: any, index: number, record: Application) => {
                    if (record.alwaysDisplayInConsole) {
                        return <Tag type={"primary"} size={"small"} color={"#2BD5FF"}>是</Tag>
                    } else {
                        return <Tag type={"primary"} size={"small"}>否</Tag>
                    }
                }}/>
                <Table.Column title="是否启用" cell={(value: any, index: number, record: Application) => {
                    if (record.enabled) {
                        return <Tag type={"primary"} size={"small"} color={"#2BD5FF"}>启用</Tag>
                    } else {
                        return <Tag type={"primary"} size={"small"}>禁用</Tag>
                    }
                }}/>
                <Table.Column title="创建时间" cell={(value: any, index: number, record: Application) => {
                    return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
                }} dataIndex="createdTime"/>
                <Table.Column title="操作" cell={renderAction}/>
            </Table>

            <PaginationLBContainer>
                <PaginationTotal>
                    共<span>{pageList?.total}</span>条记录
                </PaginationTotal>
                <Pagination current={query.current} pageSize={query.pageSize} total={pageList?.total}
                            onChange={(current: number, e: {}) => setQuery({
                                ...query,
                                current: current
                            })}/>
            </PaginationLBContainer>

            <AddClientDialog visible={addDialogVisible} toggleVisible={toggleDialogVisible} onSuccess={onAddSuccess}/>

        </PageContainer>
    )
}

export default ClientManagement
