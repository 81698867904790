import React from "react";
import Exception from "@/components/Exception";
import ErrorImage from "@/assets/error-200-200.png"

export interface ServerErrorProps {
    image?: string;
    description?: string;
}

const ServerError: React.FC<ServerErrorProps> = (props) => {
    const {image = ErrorImage, description = "服务器好像挂了你要等会了"} = props
    return (
        <Exception
            statusCode="500"
            image={image}
            description={description}
        />
    );
}

export default ServerError