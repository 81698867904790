// 解析token
export function decodeToken(token: string) {
    token = token.split('.')[1];

    token = token.replace(/-/g, '+');
    token = token.replace(/_/g, '/');
    switch (token.length % 4) {
        case 0:
            break;
        case 2:
            token += '==';
            break;
        case 3:
            token += '=';
            break;
        default:
            throw 'Invalid token';
    }

    token = decodeURIComponent(escape(atob(token)));

    token = JSON.parse(token);
    return token;
}

export function getOrigin() {
    if (!window.location.origin) {
        return window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
    } else {
        return window.location.origin;
    }
}

export const resetRoute = () => {
    window.location.href = window.location.origin
}

export function getTopDomain(): string {
    const topDomain = document.domain.split('.').slice(-2).join('.');
    return topDomain;
}
