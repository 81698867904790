import React from "react";
import {Button, Message, Table} from "@alifd/next";
import * as groupModel from "@/models/admin/group.model";
import {useToggle} from "react-use";
import {openErrorNotification} from "@/utils/notificationUtil";
import {GroupAttribute} from "@/types/admin/GroupAttribute";
import AddGroupAttributeDialog from "./AddGroupAttributeDialog";

export interface GroupAttributeListProps {
    groupId: string | undefined;
}

const GroupAttributeList: React.FC<GroupAttributeListProps> = (props) => {
    const {groupId} = props;

    const {data, isLoading} = groupModel.useFindGroupAttributeListByGroupId(groupId);
    const {mutateAsync: deleteUserAttribute} = groupModel.useDeleteGroupAttribute();
    const [visible, toggleVisible] = useToggle(false);

    const onAddSuccess = () => {
    }

    const handleDelete = (id: string) => {
        deleteUserAttribute({
            groupId: String(groupId),
            attributeId: id
        }).then(() => {
            Message.success("删除成功！");
        }).catch(reason => {
            openErrorNotification("删除失败", reason.message);
        })
    }

    const renderActions = (value: any, index: number, record: GroupAttribute) => {
        return (
            <Button text={true} onClick={() => handleDelete(record.id)}>删除</Button>
        );
    }

    return (
        <div>
            <p style={{marginTop: '0'}}>
                <Button onClick={() => toggleVisible()}>添加</Button>
            </p>
            <Table dataSource={data} loading={isLoading}>
                <Table.Column title="ID" dataIndex="id"/>
                <Table.Column title="属性名称" dataIndex="attributeName"/>
                <Table.Column title="属性值" dataIndex="attributeValue"/>
                <Table.Column title="" cell={renderActions}/>
            </Table>
            <AddGroupAttributeDialog groupId={groupId} visible={visible} toggleVisible={toggleVisible} onSuccess={onAddSuccess} />
        </div>

    )

}

export default GroupAttributeList