import {useMemo} from "react";
import {useLocation} from "react-router-dom";
import {pathToRegexp, match, parse, compile} from "path-to-regexp";

export const useURLPathParams = <K extends string>(pathRegExp: string, pathParamKeys: K[]): {[key in K]: string} | null => {
    const pathname = useLocation().pathname;
    return useMemo(() => {
        // const regexp = pathToRegexp(pathRegExp);
        // const resultArray = regexp.exec(pathname);
        const matchFn = match(pathRegExp,{ decode: decodeURIComponent });
        const matchResult = matchFn(pathname) as {params: {[key in K]: string}};
        if (!matchResult) {
            return null;
        }
        return matchResult.params;
    }, [pathname, pathRegExp])
};