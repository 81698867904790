// 通过节点label递归查找节点
import {TreeObject} from "../types/core/TreeObject";

export interface NodeParse<EXTRA> {
    (node: TreeObject<EXTRA>): TreeObject<EXTRA>;
}

// 解析转换节点
export const loopParseTreeNode = <EXTRA> (treeData: TreeObject<EXTRA>[], parser: NodeParse<EXTRA>): TreeObject<EXTRA>[] => {
    const result: TreeObject<EXTRA>[] = new Array();
    for (let item of treeData) {
        const newNode = parser(item);
        result.push(newNode);
        if (item.children && item.children.length) {
            const newChildren = loopParseTreeNode(item.children, parser);
            newNode.children = newChildren
        }
    }
    return result;
};

// 添加上下文菜单
export const loopForContextMenu = <EXTRA> (tree: TreeObject<EXTRA>[], createContextMenu: (e: any, tree: TreeObject<EXTRA>[]) => void): TreeObject<EXTRA>[] => {
    return tree.map(x => {
        x.onContextMenu = (e: any) => {
            createContextMenu(e, tree)
        };
        /*if (newNode.type === "TOP_MENU" || newNode.type === "LEFT_MENU") {
            // item.icon = <Icon type={_.lowerCase(jobInfo.jobType)}/>
            newNode.icon = <Icon type={'lock'}/>
        }*/
        if (x.children && x.children.length) {
            loopForContextMenu(x.children, createContextMenu);
        }
        return x;
    })
};

// 通过节点label递归查找节点
export const loopMatchNodeByLabel = <EXTRA> (treeData: TreeObject<EXTRA>[], label: string): TreeObject<EXTRA> | undefined => {
    for (let item of treeData) {
        if (item.label === label) {
            // selectNode = item
            return item;
        }
        if (item.children && item.children.length) {
            const matchNode = loopMatchNodeByLabel(item.children, label) as TreeObject<EXTRA>;
            if (matchNode) {
                return matchNode
            }
        }
    }
};

// 通过节点key递归查找节点
export const loopMatchNodeByKey = <EXTRA> (treeData: TreeObject<EXTRA>[], key: string): TreeObject<EXTRA> | undefined => {
    for (let item of treeData) {
        if (item.key === key) {
            return item;
        }
        if (item.children && item.children.length) {
            const matchNode = loopMatchNodeByKey(item.children, key) as TreeObject<EXTRA>;
            if (matchNode) {
                return matchNode
            }
        }
    }
};