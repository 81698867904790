import {useMutation, useQuery, useQueryClient} from "react-query";
import {HttpError} from "@/httpclient";
import {openErrorNotification} from "@/utils/notificationUtil";
import {Role} from "@/types/admin/Role";
import {PagedList} from "@/types/core/PagedList";
import * as roleService from "@/services/admin/role.service";
import {useCallback, useEffect, useState} from "react";
import {SelectItem} from "@/types/core/SelectItem";
import {QueryRoleRequest} from "@/types/admin/QueryRoleRequest";

export const PAGE_LIST_CACHE_KEY = "RolePageList";

export function useFindRolePageList(/*current: number, pageSize: number, */query: QueryRoleRequest) {
    return useQuery<PagedList<Role>, HttpError>([PAGE_LIST_CACHE_KEY, query], function () {
        return roleService.findRolePageList(query);
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}

export function useSaveRole() {
    const queryClient = useQueryClient();
    return useMutation(async function(role: Partial<Role>) {
        return roleService.saveRole(role)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useGetRoleById(roleId: string | undefined) {
    return useQuery<Role, HttpError>([], () => {
        return roleService.findRoleById(roleId);
    }, {
        enabled: !!roleId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

export function useUpdateRole() {
    const queryClient = useQueryClient();
    return useMutation(async function(role: Partial<Role>) {
        return roleService.updateRole(role)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

// 修改状态
export function useToggleStatus() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, {id: String, enabled: boolean}>(async function(params: {id: String, enabled: boolean}) {
        return roleService.toggleStatus(params)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useDeleteRole() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, string>(async function(roleId: String) {
        return roleService.deleteRole(roleId)
    }, {
        onSuccess: data => {
            queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
        }
    })
}

export function useListAllRoles() {
    return useQuery<Role[], HttpError>(["AllRoles"], function () {
        return roleService.listAllRoles();
    }, {
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    });
}

export function useListAllRolesFromSelect() {
    const [allRoleSelectItems, setAllRoleSelectItems] = useState<SelectItem[]>([])
    const {data, isLoading: isLoadingAllRole} = useListAllRoles();
    useEffect(() => {
        if (data) {
            const result = data.map(role => {
                return {
                    label: role.displayName,
                    value: role.roleId
                } as SelectItem
            })
            setAllRoleSelectItems(result)
        }
    }, [data])

    return {
        allRoleSelectItems,
        isLoadingAllRole
    }
}

export function useRefresh() {
    const queryClient = useQueryClient();
    return useCallback(() => {
        return queryClient.invalidateQueries([PAGE_LIST_CACHE_KEY])
    }, [])
}