import React from "react";
import {Button, Message, Table} from "@alifd/next";
import * as userModel from "@/models/admin/user.model"
import AddUserAttributeDialog from "./AddUserAttributeDialog";
import {useToggle} from "react-use";
import {UserAttribute} from "@/types/admin/UserAttribute";
import {openErrorNotification} from "@/utils/notificationUtil";

export interface UserAttributeListProps {
    userId: string | undefined;
}

const UserAttributeList: React.FC<UserAttributeListProps> = (props) => {
    const {userId} = props;
    const {data, isLoading} = userModel.useFindUserAttributeListByUserId(userId);
    const {mutateAsync: deleteUserAttribute} = userModel.useDeleteUserAttribute();
    const [visible, toggleVisible] = useToggle(false);

    const onAddSuccess = () => {
    }

    const handleDelete = (id: string) => {
        deleteUserAttribute({
            userId: String(userId),
            attributeId: id
        }).then(() => {
            Message.success("删除成功！");
        }).catch(reason => {
            openErrorNotification("删除失败", reason.message);
        })
    }

    const renderActions = (value: any, index: number, record: UserAttribute) => {
        return (
            <Button text={true} onClick={() => handleDelete(record.id)}>删除</Button>
        );
    }

    return (
        <div>
            <p>
                <Button onClick={() => toggleVisible()}>添加</Button>
            </p>
            <Table dataSource={data} loading={isLoading}>
                <Table.Column title="ID" dataIndex="id"/>
                <Table.Column title="属性名称" dataIndex="attributeName"/>
                <Table.Column title="属性值" dataIndex="attributeValue"/>
                <Table.Column title="" cell={renderActions}/>
            </Table>
            <AddUserAttributeDialog userId={userId} visible={visible} toggleVisible={toggleVisible} onSuccess={onAddSuccess} />
        </div>

    )
}

export default UserAttributeList