import React from "react";
import {Typography} from "@alifd/next";
import styled from "@emotion/styled";

export interface PageTitleProps {
    title: string;
}

const PageTitle: React.FC<PageTitleProps> = (props) => {
    const {title} = props;
    return (
        <TitleContainer>{title}</TitleContainer>
    )
}

export const TitleContainer = styled(Typography.Text)`
    display: block;
    // font-weight: bold;
    line-height: 1.5;
    padding-bottom: 0.5rem;
`

export default PageTitle