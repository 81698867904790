import {useLocation, useResolvedPath} from "react-router-dom";
import {useMemo} from "react";

export function useSelectedNavPaths(): string[] {
    const location = useLocation();

    return useMemo(() => {
        const pathname = location.pathname;
        let selectedNavPaths: string[] = [];
        const parts = pathname.split("/");
        let paths:string[] = [];
        for (let part of parts) {
            if (part !== "") {
                paths.push(part);
            }
        }
        const level = paths.length;
        if (level <= 0) {
            selectedNavPaths = ["/overview"]
        } else if (level === 1) {
            selectedNavPaths = [`/${paths[0]}`]
        } else if (level === 2) {
            selectedNavPaths = [`/${paths[0]}`, `/${paths[0]}/${paths[1]}`]
        }  else {
            selectedNavPaths = [`/${paths[0]}`, `/${paths[0]}/${paths[1]}`, `/${paths[0]}/${paths[1]}/${paths[2]}`]
        }
        return selectedNavPaths;
    }, [location.pathname])

}