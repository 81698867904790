import React, {useState} from "react";
import {Box, Button, Divider, Form, Input, Message, Pagination, Select, Table, Tag} from "@alifd/next";
import {Link, useNavigate} from "react-router-dom";
import * as userModel from "@/models/admin/user.model";
import * as roleModel from "@/models/admin/role.model";
import {PageContainer, PaginationLBContainer, PaginationTotal} from "@/components/lib";
import {User} from "@/types/admin/User";
import AddUserDialog from "./components/AddUserDialog";
import {useToggle} from "react-use";
import PageTitle from "@/components/PageTitle";
import dayjs from "dayjs";
import {HttpError} from "@/httpclient";
import {openErrorNotification} from "@/utils/notificationUtil";
import {Access, useAuthz} from "@/contexts/UAAContext";

const UserManagement = () => {
    const auth = useAuthz();
    const [current, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const {data: pageList, isLoading} = userModel.useFindUserPageList(current, pageSize);
    const {mutateAsync: toggleStatus} = userModel.useToggleStatus();
    const {mutateAsync: deleteRoleFn} = userModel.useDeleteUser();
    const refresh = userModel.useRefresh();

    // 查询角色
    // const [allRoles, setAllRoles] = useState<SelectItem[]>([])
    const {allRoleSelectItems, isLoadingAllRole} = roleModel.useListAllRolesFromSelect()
    const [addDialogVisible, toggleDialogVisible] = useToggle(false);

    function renderAction(value: any, index: number, record: User) {
        return <Box direction={"row"} justify={"flex-start"} align={"center"}>
            <Button type={"primary"} text={true}>
                <Link to={`/admin/user-management/${record.userId}?nickname=${record.nickname}`} state={record}>编辑</Link>
            </Button>
            <Divider direction={"ver"}/>
            <Button type={"primary"} text={true} disabled={record.buildIn}
                onClick={() => handleToggleStatus(record.userId, !record.enabled)}>
                {
                    record.enabled?"禁用":"启用"
                }
            </Button>
            <Divider direction={"ver"}/>
            <Button type={"primary"} text={true} disabled={record.buildIn} onClick={() => {handleDelete(record.userId)}}>删除</Button>
            {/*<Divider direction={"ver"}/>
            <Button text={true}>
                <Link to={`/admin/user-management/${record.userId}`} state={record}>详情</Link>
            </Button>*/}
        </Box>
    }

    async function handleRefresh() {
        await refresh()
    }

    function onAddSuccess() {
    }

    // 修改状态
    function handleToggleStatus(id: string, enabled: boolean) {
        toggleStatus({
            id,
            enabled
        }).then(() => {
            Message.success("状态更新成功！");
        }).catch((reason: HttpError) => {
            openErrorNotification("状态更新失败", reason.message)
        })
    }

    // 删除
    function handleDelete(userId: string) {
        deleteRoleFn(userId).then(() => {
            Message.success("删除成功！");
        }).catch((reason: HttpError) => {
            openErrorNotification("删除失败", reason.message)
        })
    }

    /*const handleSearchRole = _.debounce((value: string) => {
        debugger
        roleService.listAllRoles().then((roles) => {
            const items = roles.map(role => {
                return {
                    label: role.displayName,
                    value: role.roleId
                } as SelectItem
            });
            setAllRoles(items)
        });
    }, 100, {
        leading: true,
        trailing: false
    });*/

    return (
        <PageContainer>
            <Box direction={"row"} justify={"space-between"} align={"center"}>
                <PageTitle title="用户管理" />
                <Box direction={"row"} spacing={10}>
                    <Button type={"primary"} onClick={toggleDialogVisible}>添加用户</Button>
                    <Button onClick={handleRefresh}>刷新</Button>
                </Box>
            </Box>
            <div>
                <Form inline={true}>
                    <Form.Item label="用户状态">
                        <Select>
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value="1">启用</Select.Option>
                            <Select.Option value="0">禁用</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="用户角色">
                        <Select
                            showSearch={false}
                            filterLocal={false}
                            dataSource={allRoleSelectItems}
                            // onSearch={handleSearchRole}
                        >
                        </Select>

                    </Form.Item>
                    <Form.Item label="用户名">
                        <Input />
                    </Form.Item>
                    <Form.Submit type={"primary"}>搜索</Form.Submit>
                    <Form.Reset style={{marginLeft: "1rem"}}>清除</Form.Reset>
                </Form>
            </div>
            <Table dataSource={pageList?.data} loading={isLoading}>
                <Table.Column title="User ID" dataIndex="userId" />
                <Table.Column title="用户名" dataIndex="username" />
                <Table.Column title="昵称" dataIndex="nickname" />
                {/*<Table.Column title="姓名" dataIndex="name" />
                <Table.Column title="电子邮件" dataIndex="email" />
                <Table.Column title="手机号码" dataIndex="mobile" />
                <Table.Column title="头像" dataIndex="avatar" />
                */}
                <Table.Column title="多因子认证" cell={(value: any, index: number, record: User) => {
                    if (record.usingMfa) {
                        return <Tag type={"primary"} size={"small"} color={"green"}>启用</Tag>
                    } else {
                        return <Tag type={"primary"} size={"small"}>禁用</Tag>
                    }
                }} />

                <Table.Column title="账号未过期" cell={(value: any, index: number, record: User) => {
                    if (record.accountNonExpired) {
                        return <Tag type={"primary"} size={"small"} color={"#2BD5FF"}>是</Tag>
                    } else {
                        return <Tag type={"primary"} size={"small"}>否</Tag>
                    }
                }} />

                <Table.Column title="账号未锁定" cell={(value: any, index: number, record: User) => {
                    if (record.accountNonLocked) {
                        return <Tag type={"primary"} size={"small"} color={"#2BD5FF"}>是</Tag>
                    } else {
                        return <Tag type={"primary"} size={"small"}>否</Tag>
                    }
                }} />

                <Table.Column title="凭证未过期" cell={(value: any, index: number, record: User) => {
                    if (record.credentialsNonExpired) {
                        return <Tag type={"primary"} size={"small"} color={"#2BD5FF"}>是</Tag>
                    } else {
                        return <Tag type={"primary"} size={"small"}>否</Tag>
                    }
                }} />

                <Table.Column title="是否启用" cell={(value: any, index: number, record: User) => {
                    if (record.enabled) {
                        return <Tag type={"primary"} size={"small"} color={"#2BD5FF"}>启用</Tag>
                    } else {
                        return <Tag type={"primary"} size={"small"}>禁用</Tag>
                    }
                }} />
                <Table.Column title="创建时间" dataIndex="createdTime" cell={(value: any, index: number, record: User) => {
                    return <div>{dayjs(value).format('YYYY-MM-DD HH:mm:ss')}</div>
                }} />
                <Table.Column title="操作" cell={renderAction} />
            </Table>
            <PaginationLBContainer>
                <PaginationTotal>
                    共<span>{pageList?.total}</span>条记录
                </PaginationTotal>
                <Pagination current={current} pageSize={pageSize} total={pageList?.total} onChange={(current: number, e: {}) => setCurrentPage(current)}/>
            </PaginationLBContainer>

            <AddUserDialog visible={addDialogVisible} toggleVisible={toggleDialogVisible} onSuccess={onAddSuccess} />

        </PageContainer>
    )
}

export default UserManagement