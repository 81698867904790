import React from "react";
import DialogContainer from "@/components/DialogContainer";
import {Field, Form, Input, Message} from "@alifd/next";
import {openErrorNotification} from "@/utils/notificationUtil";
import * as groupModel from "@/models/admin/group.model";
import {TreeObject} from "@/types/core/TreeObject";
import {Group} from "@/types/admin/Group";

export interface AddGroupDialogProps {
    visible: boolean;
    toggleVisible: () => void;
    parent: TreeObject<Group> | undefined;
    onSuccess: (groupId: string) => void;
}

const AddGroupDialog: React.FC<AddGroupDialogProps> = (props) => {
    const {visible, toggleVisible, parent, onSuccess} = props;
    const field = Field.useField();
    const {mutateAsync} = groupModel.useSaveGroup();
    function handleOk() {
        field.validate((errors, values) => {
            if (errors) {
                return;
            }
            const group = field.getValues<Partial<Group>>();
            if (parent) {
                group.parentId = parent.key;
            }
            mutateAsync(group).then((value) => {
                Message.success("添加成功！");
                onSuccess(value.groupId);
                field.reset();
                handleClose();
            }).catch(reason => {
                openErrorNotification("添加失败", reason.message);
            })
        })
    }

    function handleClose() {
        toggleVisible()
    }

    return (
        <DialogContainer title={'添加组'} visible={visible} onOk={handleOk} onCancel={handleClose} onClose={handleClose}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}} colon={true} field={field}>
                {
                    parent?<Form.Item label="父级" required={true} isPreview={true} aria-disabled={"true"}>
                        <Input name="parentName" value={parent?.label} disabled={true}/>
                    </Form.Item>:<></>
                }
                <Form.Item label="组名称" required={true}>
                    <Input name="groupName"/>
                </Form.Item>
                <Form.Item label="显示名称" required={true}>
                    <Input name="displayName"/>
                </Form.Item>
                <Form.Item label="描述" required={true}>
                    <Input.TextArea name="description"/>
                </Form.Item>
                <Form.Item label="组类型">
                    <Input name="groupType"/>
                </Form.Item>
                <Form.Item label="排序号">
                    <Input name="sortNo"/>
                </Form.Item>
                <Form.Item label="图标">
                    <Input name="icon"/>
                </Form.Item>
            </Form>
        </DialogContainer>
    )
}

export default AddGroupDialog