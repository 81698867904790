import React, {useState} from "react";
import {Box, Button, Divider, Form, Input, Message, Pagination, Select, Table, Tag} from "@alifd/next";
import {Link} from "react-router-dom";
import * as roleModel from "@/models/admin/role.model";
import {PageContainer, PaginationLBContainer, PaginationTotal} from "@/components/lib";
import {Role} from "@/types/admin/Role";
import {useToggle} from "react-use";
import AddRoleDialog from "./components/AddRoleDialog";
import {openErrorNotification} from "@/utils/notificationUtil";
import {HttpError} from "@/httpclient";
import {QueryRoleRequest} from "@/types/admin/QueryRoleRequest";

const RoleManagement = () => {
    // const [current, setCurrentPage] = useState(0);
    // const [pageSize, setPageSize] = useState(10);
    const [query, setQuery] = useState<QueryRoleRequest>({current: 0, pageSize: 10})
    const {data: pageList, isLoading} = roleModel.useFindRolePageList(query);
    const {mutateAsync: toggleStatus} = roleModel.useToggleStatus();
    const {mutateAsync: deleteRoleFn} = roleModel.useDeleteRole();
    const refresh = roleModel.useRefresh();

    const [addDialogVisible, toggleDialogVisible] = useToggle(false);

    async function handleRefresh() {
        await refresh()
    }

    function onAddSuccess() {
    }

    // 修改状态
    function handleToggleStatus(id: string, enabled: boolean) {
        toggleStatus({
            id,
            enabled
        }).then(() => {
            Message.success("状态更新成功！");
        }).catch((reason: HttpError) => {
            openErrorNotification("状态更新失败", reason.message)
        })
    }

    // 删除
    function handleDelete(roleId: string) {
        deleteRoleFn(roleId).then(() => {
            Message.success("删除成功！");
        }).catch((reason: HttpError) => {
            openErrorNotification("删除失败", reason.message)
        })
    }

    // 渲染操作
    function renderAction(value: any, index: number, record: Role) {
        return <Box direction={"row"} justify={"flex-start"} align={"center"}>
            <Button type={"primary"} text={true}>
                <Link to={`/admin/role-management/${record.roleId}?roleName=${record.roleName}`}>编辑</Link>
            </Button>
            <Divider direction={"ver"}/>
            <Button type={"primary"} text={true} disabled={record.buildIn}
                    onClick={() => handleToggleStatus(record.roleId, !record.enabled)}>
                {
                    record.enabled?"禁用":"启用"
                }
            </Button>
            <Divider direction={"ver"}/>
            <Button type={"primary"} text={true} disabled={record.buildIn} onClick={() => handleDelete(record.roleId)}>删除</Button>
            {/*<Divider direction={"ver"}/>
            <Button text={true}>
                <Link to={`/admin/role-management/${record.roleId}`}>详情</Link>
            </Button>*/}
        </Box>
    }

    // 搜索
    const handleSubmitQuery = (values: QueryRoleRequest, errors: any) => {
        setQuery({
            ...query,
            ...values
        })
    };

    return (
        <PageContainer>
            <Box direction={"row"} justify={"space-between"} align={"center"}>
                <label>角色管理</label>
                <Box direction={"row"} spacing={10}>
                    <Button type={"primary"} onClick={toggleDialogVisible}>添加角色</Button>
                    <Button onClick={handleRefresh}>刷新</Button>
                </Box>
            </Box>
            <div>
                <Form inline={true} colon>
                    <Form.Item label="角色状态">
                        <Select name={'enabled'}>
                            <Select.Option value="">全部</Select.Option>
                            <Select.Option value="1">启用</Select.Option>
                            <Select.Option value="0">禁用</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="角色名">
                        <Input name={'roleName'}/>
                    </Form.Item>
                    <Form.Submit type={"primary"} onClick={handleSubmitQuery}>搜索</Form.Submit>
                    <Form.Reset style={{marginLeft: "1rem"}}>清除</Form.Reset>
                </Form>
            </div>
            <Table dataSource={pageList?.data} loading={isLoading}>
                <Table.Column title="Role ID" dataIndex="roleId"/>
                <Table.Column title="角色名称" dataIndex="roleName"/>
                <Table.Column title="显示名称" dataIndex="displayName"/>
                <Table.Column title="是否启用" cell={(value: any, index: number, record: Role) => {
                    if (record.enabled) {
                        return <Tag type={"primary"} size={"small"} color={"#2BD5FF"}>启用</Tag>
                    } else {
                        return <Tag type={"primary"} size={"small"}>禁用</Tag>
                    }
                }}/>
                <Table.Column title="创建时间" dataIndex="createdTime"/>
                <Table.Column title="操作" cell={renderAction}/>
            </Table>
            <PaginationLBContainer>
                <PaginationTotal>
                    共<span>{pageList?.total}</span>条记录
                </PaginationTotal>
                <Pagination current={query.current} pageSize={query.pageSize} total={pageList?.total}
                            onChange={(current: number, e: {}) => setQuery({
                                ...query,
                                current: current
                            })}/>
            </PaginationLBContainer>


            <AddRoleDialog visible={addDialogVisible} toggleVisible={toggleDialogVisible} onSuccess={onAddSuccess}/>

        </PageContainer>
    )
}

export default RoleManagement