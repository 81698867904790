import {AxiosError} from "axios";

export interface Violation {
    field: string;
    message: string;
}

export interface ErrorField {
    objectName: string;
    filed: string;
    code: string;
}

export interface ErrorResponse {
    code: number; // 错误码
    message: string; // 错误提示信息
    detail?: string; // 错误详细信息
    stacktrace?: string[];
    violations?: Violation[];
    errorFields?: ErrorField[];
}

export class HttpError extends Error {
    readonly url: string; // 发生错误请求地址
    readonly status: number;  // 错误状态
    // readonly title: string; // 错误标题
    readonly code?: number; // 错误码
    // readonly message: string; // 错误提示信息
    readonly detail?: string; // 错误详情信息
    readonly axiosError: AxiosError;
    readonly errorResponse?: ErrorResponse; // 错误响应

    constructor(url: string, axiosError: AxiosError, errorResponse?: ErrorResponse) {
        super(errorResponse?.message || axiosError.message);
        this.url = url;
        this.status = axiosError.response?.status || -1;
        this.code = errorResponse?.code;
        this.detail = errorResponse?.detail;
        this.axiosError = axiosError;
        this.errorResponse = errorResponse;
    }

}
