import httpClient from "@/utils/httpClient";
import {TreeObject} from "@/types/core/TreeObject";
import {Group} from "@/types/admin/Group";
import {GroupAttribute} from "@/types/admin/GroupAttribute";
import {User} from "@/types/admin/User";
import {TransferResponse} from "@/types/admin/TransferResponse";

export async function findGroupTree(realmId: string | undefined) {
    return httpClient.get<TreeObject<Group>[]>(`/api/v1/admin/groups/tree`)
}

export async function findGroupById(groupId: string | undefined) {
    return httpClient.get<Group>(`/api/v1/admin/groups/${groupId}`)
}

export async function saveGroup(group: Partial<Group>) {
    return httpClient.post<Group>(`/api/v1/admin/groups`, {
        data: group
    });
}

export async function deleteGroup(groupId: string) {
    return httpClient.delete(`/api/v1/admin/groups/${groupId}`);
}

export async function updateGroup(group: Partial<Group>) {
    return httpClient.put(`/api/v1/admin/groups/${group.groupId}`, {
        data: group
    });
}

export function findGroupAttributeListByGroupId(groupId: string | undefined) {
    return httpClient.get<GroupAttribute[]>(`/api/v1/admin/groups/${groupId}/attributes`)
}

export async function saveGroupAttribute(groupAttribute: Partial<GroupAttribute>) {
    return httpClient.post(`/api/v1/admin/groups/${groupAttribute.groupId}/attributes`, {
        data: groupAttribute
    });
}

export function deleteUGroupAttribute(param: { groupId: string; attributeId: string }) {
    return httpClient.delete(`/api/v1/admin/groups/${param.groupId}/attributes/${param.attributeId}`);
}

// 查询组成员列表
export function findGroupMemberListByGroupId(groupId: string | undefined) {
    return httpClient.get<User[]>(`/api/v1/admin/groups/${groupId}/members`)
}

// 查询组角色
export function findAssignedGroupRolesByGroupId(groupId: string | undefined) {
    return httpClient.get<TransferResponse>(`/api/v1/admin/permissions/groups/${groupId}/roles/transfer`)
}

// 分配组角色
export async function assignRolesToGroup(groupId: string, roleIds: string[]) {
    return httpClient.post(`/api/v1/admin/permissions/groups/${groupId}/roles`, {
        data: {
            groupId,
            roleIds
        }
    });
}