import React, {useState} from "react";
import {Icon, Nav, Shell} from "@alifd/next";
import {NavLink, Outlet, useNavigate} from "react-router-dom";
import styled from "@emotion/styled";
import {NoHeaderShell} from "@/components/NoHeaderShell";
import { useSelectedNavPaths } from "@/hooks/useSelectedNavPaths";
import FaIcon from "@/components/FaIcon";

const SystemManagement = () => {
    const navigate = useNavigate();
    const selectedNavPaths = useSelectedNavPaths();
    // nav
    const [navcollapse, setNavcollapse] = useState(false);
    const onNavCollapseChange = (collapse?: boolean) => {
        setNavcollapse(collapse || false);
        return {}
    };

    return (
        <NoHeaderShell device={"desktop"}>

            <Shell.Navigation
                collapse={navcollapse}
                onCollapseChange={onNavCollapseChange}
                style={{padding: 0}}
            >
                <NavToggle onClick={() => {setNavcollapse(!navcollapse)}}>
                    {navcollapse ? (
                        <Icon type="toggle-right" size="xs"/>
                    ) : (
                        <Icon type="toggle-left" size="xs"/>
                    )}
                </NavToggle>

                <Nav embeddable aria-label="global navigation" defaultSelectedKeys={selectedNavPaths}>
                    {/*<Nav.Item icon="calendar" key={`/system-management/sys-org-management`}>
                        <NavLink to={`/system-management/sys-org-management`}>组织机构管理</NavLink>
                    </Nav.Item>*/}
                    <Nav.Item icon="account" key={`/admin/user-management`}>
                        <NavLink to={`/admin/user-management`}>用户管理</NavLink>
                    </Nav.Item>
                    <Nav.Item icon={<FaIcon icon={"users"} size={"1x"} style={{marginRight: '4px'}} />} key={`/admin/group-management`}>
                        <NavLink to={`/admin/group-management`}>用户组管理</NavLink>
                    </Nav.Item>
                    <Nav.Item icon="set" key={`/admin/role-management`}>
                        <NavLink to={`/admin/role-management`}>角色管理</NavLink>
                    </Nav.Item>
                    <Nav.Item icon="list" key={`/admin/client-management`}>
                        <NavLink to={`/admin/client-management`}>应用管理</NavLink>
                    </Nav.Item>
                    {/*<Nav.Item icon="edit" key={`/system-management/alarm-group-management`}>
                        <NavLink to={`/system-management/alarm-group-management`}>告警组管理</NavLink>
                    </Nav.Item>*/}
                </Nav>
            </Shell.Navigation>

            <Shell.Content style={{padding: 0}}>
                <ContentContainer>
                    <Outlet />
                </ContentContainer>
            </Shell.Content>

        </NoHeaderShell>
    )
}

const NavToggle = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-bottom: 1px solid #eee;
    padding: 5px 0;
`

const ContentContainer = styled.div`
    height: calc(100vh - 54px);
    background-color: #fff;
`

export default SystemManagement