import React, {useEffect, useState} from "react";
import {UAAClient, User, UsernamePasswordAuthentication} from "@/uaa-js";
import {HttpError} from "@/httpclient";
import {Loading, Notification} from "@alifd/next";
import {useURLSearchParams} from "@/hooks/useURLSearchParams";
import httpClient from "@/utils/httpClient";
import {Route, RouteProps} from "react-router-dom";
import {Redirect} from "@/components/Redirect";
import Forbidden from "@/components/Forbidden";
import _ from "lodash";
import {match} from "path-to-regexp";

export interface AuthenticationProps {
    client: UAAClient;
}

export interface ValueType {
    user: User | undefined;
    login: (authentication: UsernamePasswordAuthentication, callback?: () => void) => Promise<void>;
    logout: (callback?: () => void) => Promise<void>;
    // authorization
    isAuthenticated: () => boolean; // 是否为已认证用户
    isAnonymous: () => boolean; // 是否为匿名用户
    isFullyAuthenticated: () => boolean; // 是否既不是匿名用户也不是记住我用户
    isRememberMe: () => boolean; // 判断是否通过记住我登录
    hasRole: (role: string) => boolean; // 判断是否拥有某个角色
    hasAnyRole: (roles: string[]) => boolean; // 判断是否拥有指定的任意一个角色
    hasGroup: (group: string) => boolean; // 判断是否拥有某个用户组
    hasAnyGroup: (groups: string[]) => boolean; // 判断是否拥有指定的任意一个用户组
    hasAuthority: (authority: string) => boolean; // 判断是否拥有某个权限
    hasAnyAuthority: (authorities: string[]) => boolean; // 判断是否拥有指定的任意一个权限
    hasPermission: (pathname: string) => boolean; // 判断是否有路由访问权限
    permitAll: () => boolean; // 通过所有
    denyAll: () => boolean; // 拒绝所有
}

const UAAContext = React.createContext<ValueType | undefined>(undefined);
UAAContext.displayName = "UAAContext";

export const UAAProvider: React.FC<AuthenticationProps> = ({client, children}) => {
    // user state
    const [user, setUser] = useState<User | undefined>(undefined);
    const [{redirect_uri}] = useURLSearchParams(["redirect_uri"]);

    const login = async (authentication: UsernamePasswordAuthentication, callback?: () => void) => {
        client.token(authentication).then(() => {
            client.userInfo().then((user) => {
                setUser(user);
                if (callback) {
                    callback();
                }
            }).catch((error: HttpError) => {
                if (error.status === 403102) {
                    // token已过期，请求刷新token请求
                    client.refreshToken().then(() => {
                        // 重新请求
                        return httpClient.request(error.axiosError.config);
                    }).catch(reason => {
                        client.logout().finally(() => {
                            window.location.href = window.location.origin
                        });
                    })
                } else {
                    Notification.open({
                        type: "error",
                        title: error.status,
                        content: error.message
                    })
                }
            })
        }).catch((error: HttpError) => {
            Notification.open({
                type: "error",
                title: error.status,
                content: error.message
            })
        })
    }

    const logout = async (callback?: () => void) => {
        client.logout().then(() => {
            // clear user
            setUser(undefined)
            if (callback) {
                callback()
            }
        });
        return Promise.resolve()
    };

    const accessToken = client.retrieveAccessToken();
    useEffect(() => {
        if (accessToken && !user) {
            // fetch user
            client.userInfo().then((user) => {
                if (user) {
                    setUser(user)
                }
            }).catch((error: HttpError) => {
                if (error.code === 403102) {
                    client.refreshToken().then(() => {
                        return httpClient.request(error.axiosError.config)
                    }).catch(error => {
                        client.logout().then(() => {
                            window.location.href = window.location.origin
                        }).catch(() => {
                            Notification.open({
                                type: "error",
                                title: error.status,
                                content: error.message,
                            })
                        })
                    })
                } else {
                    Notification.open({
                        type: "error",
                        title: error.status,
                        content: error.message,
                        onClose: () => {
                            if (error.status === 401) {
                                window.location.href = window.location.origin
                            }
                        }
                    })
                }
            })
        }

    }, [accessToken])

    if (accessToken && !user) {
        return (
            <Loading fullScreen={true}/>
        )
    }

    if (user && redirect_uri) {
        window.location.href = redirect_uri
        return (
            <></>
        )
    }

    // 是否已认证
    const isAuthenticated = (): boolean => {
        return !!user
    };
    // 是否完全认证
    const isFullyAuthenticated = (): boolean => {
        return !!user
    };
    // 是否为匿名用户
    const isAnonymous = (): boolean => {
        if (user && user.username === "anonymous") {
            return true;
        }
        return false
    };
    // 是否为rememberMe登录
    const isRememberMe = (): boolean => {
        return false
    };
    // 是否具有指定角色
    const hasRole = (role: string): boolean => {
        if (user && role) {
            return _.includes(user.roles, "ROLE_" + role);
        }
        return false
    };
    // 如果当前用户拥有指定角色中的任意一个则返回true
    const hasAnyRole = (roles: string[]): boolean => {
        if (user && roles && roles.length) {
            // 判断交集长度是否 > 0
            return _.intersection(user.roles, roles).length > 0;
        }
        return false
    };
    // 是否具有指定用户组
    const hasGroup = (group: string): boolean => {
        if (user && group) {
            return _.includes(user.groups, "GROUP_" + group);
        }
        return false
    };
    // 如果当前用户拥有指定用户组中的任意一个则返回true
    const hasAnyGroup = (groups: string[]): boolean => {
        if (user && groups && groups.length) {
            // 判断交集长度是否 > 0
            const values = groups.map(x => "GROUP_" + x);
            return _.intersection(user.groups, values).length > 0;
        }
        return false
    };
    // 是否具有指定权限
    const hasAuthority = (authority: string): boolean => {
        if (user && authority) {
            return _.includes(user.authorities, authority);
        }
        return false
    };
    // 如果当前用户拥有指定权限中的任意一个则返回true
    const hasAnyAuthority = (authorities: string[]): boolean => {
        if (user && authorities && authorities.length) {
            // 判断交集长度是否 > 0
            return _.intersection(user.authorities, authorities).length > 0;
        }
        return false
    };
    // 是否具有 pathname 访问权限
    const hasPermission = (pathname: string): boolean => {
        if (user) {
            if (user.superAdmin === true) {
                return true
            }
            for (let resource of user.resources) {
                if (!resource.routePath) {
                    continue;
                }
                const matchFn = match(resource.routePath, {decode: decodeURIComponent});
                if (matchFn(pathname)) {
                    return true;
                }
                /*
                if (resource.routePath === pathname) {
                    return true;
                }
                */
            }
        }
        return false;
    };

    // 总是返回true
    const permitAll = (): boolean => {
        return true
    };

    // 总是返回false
    const denyAll = (): boolean => {
        return false
    };

    const value = {
        user,
        login,
        logout,
        isAuthenticated,
        isFullyAuthenticated,
        isAnonymous,
        isRememberMe,
        hasRole,
        hasAnyRole,
        hasGroup,
        hasAnyGroup,
        hasAuthority,
        hasAnyAuthority,
        hasPermission,
        permitAll,
        denyAll
    };

    return <UAAContext.Provider children={children} value={value}></UAAContext.Provider>
};

export const useAuthz = () => {
    const context = React.useContext(UAAContext);
    if (!context) {
        throw new Error("useAuth 必须在AuthProvider中使用")
    }
    return context
}

export interface AccessProps {
    accessible: boolean;
    fallback?: React.ReactNode;
}

export const Access: React.FC<AccessProps> = (props) => {
    const {accessible, fallback, children} = props
    return (
        <>
            {accessible ? children : fallback || <></>}
        </>
    )
}

export const PrivateRoute: React.FC<RouteProps> = (props) => {
    const {children, path, ...rest} = props;
    const auth = useAuthz();

    if (!auth.user) {
        return <Redirect to={{pathname: "/login"}} state={{from: path}}/>
    }

    const pass = path ? auth.hasPermission(path) : true;

    return pass ? <Route {...rest} children={children}/> : <Route element={<Forbidden/>}/>
}


