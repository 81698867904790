import React from "react";
import DialogContainer from "@/components/DialogContainer";
import {Field, Form, Input, Message} from "@alifd/next";
import {openErrorNotification} from "@/utils/notificationUtil";
import {useSaveRole} from "@/models/admin/role.model";
import {Role} from "@/types/admin/Role";

export interface AddRoleDialogProps {
    visible: boolean;
    toggleVisible: () => void;
    onSuccess: () => void;
}

const AddRoleDialog: React.FC<AddRoleDialogProps> = (props) => {
    const {visible, toggleVisible, onSuccess} = props
    const field = Field.useField();
    const {mutateAsync} = useSaveRole();

    function handleOk() {
        field.validate((errors, values) => {
            if (errors) {
                return;
            }
            const role = field.getValues<Partial<Role>>();
            mutateAsync(role).then(() => {
                Message.success("新建角色成功！");
                onSuccess();
                handleClose()
            }).catch(reason => {
                openErrorNotification("新建角色失败", reason.message);
            })
        })
    }

    function handleClose() {
        toggleVisible()
    }

    return (
        <DialogContainer title={'添加角色'} visible={visible} onOk={handleOk} onCancel={handleClose} onClose={handleClose}>
            <Form labelCol={{span: 6}} wrapperCol={{span: 18}} colon={true} field={field}>
                <Form.Item label="角色名称" required={true}>
                    <Input name={'roleName'} />
                </Form.Item>
                <Form.Item label="显示名称" required={true}>
                    <Input name="displayName" />
                </Form.Item>
                <Form.Item label="角色描述" required={true}>
                    <Input.TextArea name="description" />
                </Form.Item>
            </Form>
        </DialogContainer>
    )
}

export default AddRoleDialog