import React, {useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {
    Box,
    Breadcrumb,
    Button,
    Field,
    Form,
    Grid,
    Icon,
    Input,
    Loading,
    Message,
    Radio,
    Switch,
    Tab, Upload
} from "@alifd/next";
import {PageContainer} from "@/components/lib";
import {useURLPathParams} from "@/hooks/useURLPathParams";
import * as clientModel from "@/models/admin/client.model";
import {defaultFormItemLayout} from "@/contants/Constans";
import {openErrorNotification} from "@/utils/notificationUtil";
import {Application} from "@/types/admin/Application";
import TabContentContainer from "@/components/TabContentContainer";
import {nanoid} from "nanoid";
import {ApplicationSecretUpdateRequest} from "@/types/admin/AppSecretUpdateRequest";
import ResourceManagement from "./components/ResourceManagement";
import {HttpError} from "@/httpclient";
import {useURLSearchParams} from "@/hooks/useURLSearchParams";

const formItemLayout = {
    colSpan: 12,
};

const ApplicationDetail = () => {
    const navigate = useNavigate();
    const pathParams = useURLPathParams("/admin/client-management/:appId", ["appId"]);
    const [queryParams] = useURLSearchParams(["displayName"]);
    const displayName = queryParams.displayName;
    const appId = pathParams?.appId;

    const {data: clientInfo, isLoading} = clientModel.useGetAppById(appId);
    const {mutateAsync: updateClientMutateAsync} = clientModel.useUpdateClient();
    const {mutateAsync: updateClientSecretSecretAsync} = clientModel.useUpdateAppSecret(appId);
    const {mutateAsync: deleteClientFn} = clientModel.useDeleteClient();

    const clientInfoField = Field.useField({forceUpdate: false, values: clientInfo});

    useEffect(() => {
        if (clientInfo) {
            clientInfoField.setValues(clientInfo)
        }
    }, [clientInfo]);


    // 提交客户端信息
    function handleSubmitClientInfo() {
        clientInfoField.validate((errors, values) => {
            if (errors) {
                return;
            }
            debugger
            const client = clientInfoField.getValues<Partial<Application>>();
            updateClientMutateAsync(client).then(() => {
                Message.success("更新成功！");
            }).catch(reason => {
                openErrorNotification("更新失败", reason.message);
            })
        })
    }

    // 生成秘钥
    function handleGenerateSecret() {
        const clientSecret = nanoid(36);
        const request: ApplicationSecretUpdateRequest = {
            appId: appId || '',
            appSecret: clientSecret
        }
        updateClientSecretSecretAsync(request).then(() => {
            Message.success("生成安全秘钥成功！");
        }).catch(reason => {
            openErrorNotification("生成安全秘钥失败", reason.message);
        })
    }

    // 删除
    function handleDelete() {
        if (appId) {
            deleteClientFn(appId).then(() => {
                Message.success("删除成功！");
                navigate(-1);
            }).catch((reason: HttpError) => {
                openErrorNotification("删除失败", reason.message)
            })
        }
    }

    function beforeUpload(file: any, options: any) {
        debugger
    }

    function handleSelectImg(uploadFiles: Array<any>, value: Array<any>) {
        debugger
    }

    return isLoading ? (<Loading fullScreen={true}/>) : (
        <PageContainer>
            <Breadcrumb>
                <Breadcrumb.Item link="/admin/client-management">应用管理</Breadcrumb.Item>
                <Breadcrumb.Item>
                    <b>{displayName}</b>
                </Breadcrumb.Item>
            </Breadcrumb>

            <Box direction={"row"} justify={"flex-start"} align={"center"} spacing={10} style={{marginTop: '1rem'}}>
                {/*<Icon size={"xs"} type="arrow-left" />*/}
                <label>{displayName}</label>
                <Icon size={"small"} type="ashbin" onClick={handleDelete} role={"button"} aria-label="icon ashbin"
                      style={{cursor: "pointer"}}/>
            </Box>

            <Tab>
                <Tab.Item title="应用信息" key="1">
                    <TabContentContainer>
                        <div style={{minWidth: '60rem'}}>
                            <Form {...defaultFormItemLayout} colon={true} field={clientInfoField}>
                                <Form.Item label="APP ID" required={true} isPreview={true}>
                                    <Input name={'appId'}/>
                                </Form.Item>
                                <Form.Item label="APP KEY" required={true} isPreview={true}>
                                    <Input name="appKey"/>
                                </Form.Item>
                                <Form.Item label="显示名称">
                                    <Input name="displayName"/>
                                </Form.Item>
                                <Form.Item label="客户端类型">
                                    <Radio.Group shape="button" name={"appType"} defaultValue={'frontend'}>
                                        <Radio value="web">web应用</Radio>
                                        <Radio value="app">app</Radio>
                                        <Radio value="frontend">前端</Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item label="描述">
                                    <Input name="description"/>
                                </Form.Item>
                                <Form.Item label="应用主页">
                                    <Input name="homepageUrl"/>
                                </Form.Item>
                                <Form.Item label="是否在控制台显示">
                                    <Switch name="alwaysDisplayInConsole"/>
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="应用图标16*16">
                                    <Upload shape="card" name="icon16"
                                            autoUpload={false}
                                            useDataURL={true}
                                            listType={"image"}
                                            limit={1}
                                            multiple={false}
                                            accept="image/png, image/jpg, image/jpeg, image/gif, image/bmp"
                                            onSelect={handleSelectImg}
                                            beforeUpload={beforeUpload}>上传图片</Upload>
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="应用图标80*80">
                                    <Upload shape="card" name="icon80">上传图片</Upload>
                                </Form.Item>
                                <Form.Item {...formItemLayout} label="应用图标120*120">
                                    <Upload shape="card" name="icon120">上传图片</Upload>
                                </Form.Item>
                                <Form.Item label="是否启用">
                                    <Switch name="enabled"/>
                                </Form.Item>
                            </Form>

                            <Grid.Row>
                                <Grid.Col span="8"></Grid.Col>
                                <Grid.Col span="16">
                                    <Button type={"primary"} onClick={handleSubmitClientInfo}>提交</Button>
                                </Grid.Col>
                            </Grid.Row>
                        </div>
                    </TabContentContainer>
                </Tab.Item>
                <Tab.Item title="安全秘钥" key="2">
                    <TabContentContainer>
                        {/*<Form inline={true} colon={true}>
                           <Form.Item label="appID" required={true} isPreview={true}>
                                <Input name={'appId'}/>
                            </Form.Item>
                            <Form.Item label="客户端名称" required={true} isPreview={true}>
                                <Input name="name"/>
                            </Form.Item>
                        </Form>*/}

                        <Box direction={"row"} spacing={10}>
                            <Input name="clientSecret" value={clientInfo?.appSecret} readOnly={true}
                                   style={{width: '28rem'}}/>
                            <Button type={"primary"} onClick={handleGenerateSecret}>生成秘钥</Button>
                        </Box>

                    </TabContentContainer>
                </Tab.Item>
                <Tab.Item title="资源" key="3">
                    <ResourceManagement appId={appId}/>
                </Tab.Item>

            </Tab>

        </PageContainer>
    )
}

export default ApplicationDetail
