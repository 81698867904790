import {Message, Transfer, Tree} from "@alifd/next";
import React, {useEffect, useState} from "react";
import {TransferItem} from "@/types/core/TransferItem";
import * as userModel from "@/models/admin/user.model";
import {HttpError} from "@/httpclient";
import {openErrorNotification} from "@/utils/notificationUtil";

export interface AssignGroupToUserProps {
    userId: string;
}

const treeDataSource: TransferItem[] = [
    {
        label: "Form",
        key: "2",
        value: "2",
        children: [
            {
                label: "Input",
                key: "4",
                value: "4"
            },
            {
                label: "Field",
                key: "7",
                value: "7"
            },
            {
                label: "Select",
                key: "5",
                value: "5"
            }
        ]
    },
    {
        label: "Display",
        key: "3",
        value: "3",
        children: [
            {
                label: "Table",
                key: "6",
                value: "6"
            }
        ]
    },
    {
        label: "Data",
        key: "8",
        value: "8"
    }
];

const AssignGroupToUser: React.FC<AssignGroupToUserProps> = (props) => {
    const {userId} = props;

    // 查找已分配给用户的组
    const [assignedGroupIds, setAssignedGroupIds] = useState<string[]>([]);
    const {data: transferResponse} = userModel.useFindAssignedGroupsByUserId(userId);

    // 保存分配组给角色
    const {mutateAsync: assignGroupsToUser} = userModel.useAssignGroupsToUser(userId);

    useEffect(() => {
        setAssignedGroupIds(transferResponse?.keys || [])
    }, [transferResponse]);

    const transferDataSource: TransferItem[] = [];
    function flatten(list:TransferItem[] = []) {
        list.forEach(item => {
            transferDataSource.push(item);
            if (item.children) {
                flatten(item.children);
            }
        });
    }
    flatten(transferResponse?.dataSource);

    function handleChangeRole(value: string[], data: TransferItem[], extra: {}) {
        assignGroupsToUser(value).then(() => {
            Message.success("添加成功");
            setAssignedGroupIds(value)
        }).catch((reason: HttpError) => {
            openErrorNotification(reason.status, reason.message);
        })
    }

    function getTreeDataSource(dataSource: TransferItem[] = [], value: string[]) {
        return dataSource.map(({ children, ...props }) => (
            <Tree.Node
                {...props}
                disabled={props.disabled || value.includes(props.value)}
                key={props.value}
            >
                {getTreeDataSource(children, value)}
            </Tree.Node>
        ));
    }

    return (
        <Transfer
            value={assignedGroupIds}
            dataSource={transferDataSource}
            listStyle={{ width: "200px", height: "192px" }}
            onChange={handleChangeRole}
            titles={["未选择角色", "已选择角色"]}
        >
            {({ position, onChange, value }: {position: any, onChange: any, value: any}) => {
                if (position === "left") {
                    return (
                        <Tree
                            defaultExpandAll={true}
                            checkable={true}
                            checkStrictly={false}
                            style={{ padding: "10px" }}
                            checkedKeys={value}
                            onCheck={(keys, extra: any) => {
                                const newValues = extra.checkedNodes.map(
                                    (item: any) => item.props.value
                                );
                                onChange(position, newValues);
                            }}
                        >
                            {getTreeDataSource(transferResponse?.dataSource, assignedGroupIds)}
                        </Tree>
                    );
                }
            }}
        </Transfer>
    )
}
export default AssignGroupToUser