import {useMutation, useQuery, useQueryClient} from "react-query";
import {HttpError} from "@/httpclient";
import * as groupService from "@/services/admin/group.service";
import {TreeObject} from "@/types/core/TreeObject";
import {useCallback} from "react";
import {Group} from "@/types/admin/Group";
import {openErrorNotification} from "@/utils/notificationUtil";
import {User} from "@/types/admin/User";
import {GroupAttribute} from "@/types/admin/GroupAttribute";
import {TransferResponse} from "@/types/admin/TransferResponse";

export const TREE_CACHE_KEY = "GroupTree";

export function useFindGroupTree(realmId: string | undefined) {
    return useQuery<TreeObject<Group>[], HttpError>([TREE_CACHE_KEY], () => {
        return groupService.findGroupTree(realmId)
    }, {
    })
}

export function useFindGroupById(groupId: string | undefined) {
    return useQuery<Group, HttpError>(["FindResourceById", groupId], () => {
        return groupService.findGroupById(groupId)
    }, {
        enabled: !!groupId
    })
}

export function useSaveGroup() {
    const queryClient = useQueryClient();
    return useMutation<Group, HttpError, Partial<Group>>(async (group: Partial<Group>) => {
        return groupService.saveGroup(group)
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(TREE_CACHE_KEY)
        }
    })
}

export function useDeleteGroup() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, string>(async (resourceId: string) => {
        return groupService.deleteGroup(resourceId)
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(TREE_CACHE_KEY)
        }
    })
}

export function useUpdateGroup() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, Partial<Group>>(async (resource: Partial<Group>) => {
        return groupService.updateGroup(resource)
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(TREE_CACHE_KEY)
        }
    })
}

export function useRefreshGroupTree() {
    const queryClient = useQueryClient();
    return useCallback(() => {
        return queryClient.invalidateQueries([TREE_CACHE_KEY])
    }, [queryClient])
}


// 查询用户组属性列表
export function useFindGroupAttributeListByGroupId(groupId: string | undefined) {
    return useQuery<GroupAttribute[], HttpError>(['GroupAttributeListByGroupId', groupId], () => {
        return groupService.findGroupAttributeListByGroupId(groupId);
    }, {
        enabled: !!groupId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

export function useSaveGroupAttribute() {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, Partial<GroupAttribute>>(async function(groupAttribute: Partial<GroupAttribute>) {
        return groupService.saveGroupAttribute(groupAttribute);
    }, {
        onSuccess: async data => {
            await queryClient.invalidateQueries(["GroupAttributeListByGroupId"])
        }
    })
}

export function useDeleteGroupAttribute() {
    const queryClient = useQueryClient();
    return useMutation(async function(param: {groupId: string, attributeId: string}) {
        return groupService.deleteUGroupAttribute(param)
    }, {
        onSuccess: async data => {
            await queryClient.invalidateQueries(["GroupAttributeListByGroupId"])
        }
    })
}


// 查询用户组成员列表
export function useFindGroupMemberListByGroupId(groupId: string | undefined) {
    return useQuery<User[], HttpError>(['GroupMemberListByGroupId', groupId], () => {
        return groupService.findGroupMemberListByGroupId(groupId);
    }, {
        enabled: !!groupId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

// 查询组角色列表
export function useFindAssignedGroupRolesByGroupId(groupId: string | undefined) {
    return useQuery<TransferResponse, HttpError>(['RoleTransferByGroupId', groupId], () => {
        return groupService.findAssignedGroupRolesByGroupId(groupId);
    }, {
        enabled: !!groupId,
        onError: err => {
            openErrorNotification(err.status, err.message);
        }
    })
}

// 保存分配组给角色
export function useAssignRolesToGroup(groupId: string) {
    const queryClient = useQueryClient();
    return useMutation<unknown, HttpError, string[]>(async function(roleIds: string[]) {
        return groupService.assignRolesToGroup(groupId, roleIds);
    }, {
        onSuccess: async data => {
            await queryClient.invalidateQueries(["GroupAttributeListByGroupId"])
        }
    })
}